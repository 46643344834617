<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div
      class="media profile-media"
    >
      <Vue3Lottie
        :animation-data="shipAnimation"
        :height="35"
        :width="35"
      />
      <div class="media-body">
        <span>{{ nameUser }}</span>
        <p class="mb-0 font-roboto">
          {{ emailUser }}
          <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul
      class="profile-dropdown onhover-show-div"
      style="top: 37px !important"
    >
      <!-- <li>
        <router-link :to="'/users/profile'">
          <vue-feather type="user"></vue-feather><span>Account </span>
        </router-link>
      </li>
      <li>
        <router-link :to="'/email/readMail'">
          <vue-feather type="mail"></vue-feather><span>Inbox</span>
        </router-link>
      </li>
      <li>
        <router-link :to="'/app/task'">
          <vue-feather type="file-text"></vue-feather><span>Taskboard</span>
        </router-link>
      </li>
      <li>
        <router-link :to="'/pages/search'">
          <vue-feather type="settings"></vue-feather><span>Settings</span>
        </router-link>
      </li> -->
      <li>
        <a @click="logout">
          <vue-feather type="log-in"></vue-feather><span>{{ $t("logOut") }}</span>
        </a>
      </li>
    </ul>
  </li>
</template>

<script setup>
import { useAuthStore } from "@/auth/store/authStore"
import { storeToRefs } from "pinia"
import { computed } from "vue"
import { Vue3Lottie } from "vue3-lottie"
import shipAnimation from "@/assets/lotties/shipAnimation.json"

const store = useAuthStore()

const { userInfo } = storeToRefs(store)

const nameUser = computed(() => {
  return userInfo?.value?.name || ""
})

const emailUser = computed(() => {
  return userInfo?.value?.email || ""
})

const logout = () => {
  store.logout()
}
</script>

<style scoped>
:deep(.sunrise-icon svg) {
  height: 50px !important;
  width: 50px !important;
}
</style>
