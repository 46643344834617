<template>
  <div class="card">
    <div
      v-if="$slots.header || title"
      class="card-header pb-4"
    >
      <slot name="header">
        <h5>
          {{ title }}
        </h5>
        <span v-if="subTitle">
          {{ subTitle }}
        </span>
      </slot>
    </div>
    <div class="card-body">
      <slot></slot>
    </div>
    <div
      v-if="$slots.footer"
      class="card-footer"
    >
      <div>
        <slot name="footer"></slot>
      </div>
    </div>
    <div
      v-if="$slots.actions"
      class="card-footer text-end"
    >
      <div>
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actions: Boolean,
    title: String,
    subTitle: String,
  },
}
</script>
