<template>
  <div>
    <footer
      style="margin:0 !important;"
      class="footer"
      :class="footer == 'footer-dark' ? 'footer-dark' : ''"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">Copyright {{ currentYear }} © Nexus</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "Footerpage",
  data() {
    return {
      customizer: false,
    }
  },
  computed: {
    ...mapGetters({
      footer: "layout/footer",
    }),
    currentYear() {
      return new Date().getFullYear()
    }
  },
}
</script>
