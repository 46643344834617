import tags from "../../data/tags.json"
const state = {
  activeclass: "pills-created-tab",
  tags: tags.data,
}
const getters = {
  activeclass: (state) => {
    return state.activeclass
  },
}
const mutations = {
  active: (state, payload) => {
    state.activeclass = payload
  },
  add: (state, payload) => {
    state.tags.push(payload)
  },
}
const actions = {
  active: (context, payload) => {
    context.commit("active", payload)
  },
}
export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
