import bookmark from "../../data/bookmark.json"
import projects from "../../data/project.json"
import orederhistory from "../../data/orederhistory.json"
import Knowledgebase from "../../data/Knowledgebase.json"
import usercard from "../../data/usercard.json"

const state = {
  activeclass: "pills_created",
  allprojects: projects.all,
  bookmark: bookmark.data,
  doingprojects: projects.Doing,
  doneprojects: projects.Done,
  favourite: [],
  knowledgebasedata: Knowledgebase.data,
  knowledgebasevideo: Knowledgebase.latest_video,
  orederhistory: orederhistory.data,
  usercard: usercard.data,
}

const getters = {
  activeclass: (state) => {
    return state.activeclass
  },
}

const mutations = {
  active: (state, payload) => {
    state.activeclass = payload
  },
  add: (state, payload) => {
    state.bookmark.push(payload)
  },
  getActive: (state, payload) => {
    let objIndex = state.favourite.findIndex((obj) => obj.id === payload)
    if (objIndex > -1) {
      return true
    } else {
      return false
    }
  },
  setFavourite: (state, payload) => {
    let objIndex = state.favourite.findIndex((obj) => obj.id === payload.id)
    if (objIndex > -1) {
      state.favourite.splice(objIndex, 1)
    } else {
      state.favourite.push(payload)
    }
  },
}

const actions = {
  active: (context, payload) => {
    context.commit("active", payload)
  },
  getActive: (context, payload) => {
    context.commit("getActive", payload)
  },
  setFavourite: (context, payload) => {
    context.commit("setFavourite", payload)
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
