<template>
  <div class="dropdown icon-dropdown">
    <button
      id="incomedropdown"
      class="btn dropdown-toggle"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="icon-more-alt"></i>
    </button>
    <div
      class="dropdown-menu dropdown-menu-end"
      aria-labelledby="incomedropdown"
    >
      <a
        class="dropdown-item"
        href="#"
      >Today</a><a
        class="dropdown-item"
        href="#"
      >Tomorrow</a><a
        class="dropdown-item"
        href="#"
      >Yesterday
      </a>
    </div>
  </div>
</template>
