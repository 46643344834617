export var menuItems = {
  data: [
    {
      headTitle1: "General",
      headTitle2: "Dashboards,Widgets & Layout.",
      type: "headtitle",
    },
    {
      title: "Tracking",
      active: false,
      badgeType: "light-primary",
      badgeValue: null,
      children: [
        {
          active: false,
          path: "/tracking/shipment-movements",
          title: "Shipment Movements",
          type: "link",
        },
      ],
      icon: "stroke-maps",
      iconf: "fill-home",
      type: "sub",
    },
    {
      title: "Dashboard",
      active: false,
      badgeType: "light-primary",
      badgeValue: null,
      children: [
        {
          active: false,
          path: "/dashboard/shipment-on-going",
          title: "Shipments Overview",
          type: "link",
        },
        // {
        //   active: false,
        //   path: "/dashboard/shipment-arrived",
        //   title: "Shipment Arrived",
        //   type: "link",
        // },
      ],
      icon: "stroke-home",
      iconf: "fill-home",
      type: "sub",
    },
  ],
}
