import apiClient from "@/plugins/axios"

const BASE_URL = "powerembedded"

export const getShipmentOngoinReports = async (userEmail) => {
  const response = await apiClient.post(`${BASE_URL}/ongoing`, { userEmail })

  return response
}

export const getShipmentArrivedReports = async () => {
  const response = await apiClient.post(`${BASE_URL}/arrived`)

  return response
}
