export const activities = [
  {
    bnb: "+0.3BNB",
    bnbClass: "font-success",
    image: require("@/assets/images/dashboard/user/3.jpg"),
    name: "Anna K.",
    number: "29.09.22",
    to: "0x187...12bb",
  },
  {
    bnb: "+0.3BNB",
    bnbClass: "font-success",
    image: require("@/assets/images/dashboard/user/12.jpg"),
    name: "Guy Hawkins",
    number: "29.09.22",
    to: "0x187...12bb",
  },
  {
    bnb: "-0.1BNB",
    bnbClass: "font-danger",
    image: require("@/assets/images/dashboard/user/10.jpg"),
    name: "Jenny Wilson",
    number: "29.09.22",
    to: "0x187...12bb",
  },
  {
    bnb: "+0.3BNB",
    bnbClass: "font-success",
    image: require("@/assets/images/dashboard/user/11.jpg"),
    name: "Jacob B.",
    number: "29.09.22",
    to: "0x187...12bb",
  },
  {
    bnb: "-0.2BNB",
    bnbClass: "font-danger",
    image: require("@/assets/images/dashboard/user/13.jpg"),
    name: "	Esther Howard",
    number: "29.09.22",
    to: "0x187...12bb",
  },
  {
    bnb: "+0.3BNB",
    bnbClass: "font-success",
    image: require("@/assets/images/dashboard/user/5.jpg"),
    name: "Leslie Alexander",
    number: "29.09.22",
    to: "0x187...12bb",
  },
]

export const markets = [
  {
    balance: "+11.67%",
    balanceClass: "badge badge-light-success rounded-pill",
    name: "Coinmarketcap",
  },
  {
    balance: "+11.67%",
    balanceClass: "badge badge-light-success rounded-pill",
    name: "Binance",
  },
  {
    balance: "+11.67%",
    balanceClass: "badge badge-light-secondary rounded-pill",
    name: "Coinbase",
  },
  {
    balance: "+13.67%",
    balanceClass: "badge badge-light-success rounded-pill",
    name: "Yobit",
  },
]

export const alls = [
  {
    amount: "$236.89",
    companyStatus: "Buy BTC",
    date: "14 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 BTC",
    trandingClass: "trending-up",
  },
  {
    amount: "$116.89",
    companyStatus: "Sell ETH",
    date: "25 Mar, 2022",
    iconClass: "font-danger me-2",
    number: "0.089 ETH",
    trandingClass: "trending-down",
  },
  {
    amount: "$236.89",
    companyStatus: "Buy LTC",
    date: "28 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 LTC",
    trandingClass: "trending-up",
  },
  {
    amount: "$29.89",
    companyStatus: "Buy LTC",
    date: "05 Apr, 2022",
    iconClass: "font-success me-2",
    number: "0.089 LTC",
    trandingClass: "trending-up",
  },
  {
    amount: "$236.89",
    companyStatus: "Sell BTC",
    date: "16 Apr, 2022",
    iconClass: "font-danger me-2",
    number: "0.012 BTC",
    trandingClass: "trending-down",
  },
  {
    amount: "$236.89",
    companyStatus: "Buy BTC",
    date: "14 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 BTC",
    trandingClass: "trending-up",
  },
  {
    amount: "$116.89",
    companyStatus: "Sell ETH",
    date: "25 Mar, 2022",
    iconClass: "font-danger me-2",
    number: "0.089 ETH",
    trandingClass: "trending-down",
  },
  {
    amount: "$236.89",
    companyStatus: "Buy LTC",
    date: "28 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 LTC",
    trandingClass: "trending-up",
  },
]

export const buys = [
  {
    amount: "$105.00",
    companyStatus: "Buy LTC",
    date: "30 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.010 LTC",
    trandingClass: "trending-up",
  },
  {
    amount: "$120.74",
    companyStatus: "Buy ETH",
    date: "05 Apr, 2022",
    iconClass: "font-danger me-2",
    number: "0.089 ETH",
    trandingClass: "trending-down",
  },
  {
    amount: "$236.89",
    companyStatus: "Buy BTC",
    date: "14 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 BTC",
    trandingClass: "trending-up",
  },

  {
    amount: "$29.89",
    companyStatus: "Buy LTC",
    date: "05 Apr, 2022",
    iconClass: "font-success me-2",
    number: "0.089 LTC",
    trandingClass: "trending-up",
  },
  {
    amount: "$380.89",
    companyStatus: "Buy BTC",
    date: "17 Sep, 2022",
    iconClass: "font-success me-2",
    number: "0.08 BTC",
    trandingClass: "trending-down",
  },
  {
    amount: "$105.00",
    companyStatus: "Buy LTC",
    date: "30 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.010 LTC",
    trandingClass: "trending-up",
  },
  {
    amount: "$120.74",
    companyStatus: "Buy ETH",
    date: "5 Apr, 2022",
    iconClass: "font-danger me-2",
    number: "0.075 ETH",
    trandingClass: "trending-down",
  },
  {
    amount: "$236.89",
    companyStatus: "Buy BTC",
    date: "14 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 BTC",
    trandingClass: "trending-up",
  },
]

export const sells = [
  {
    amount: "$236.89",
    companyStatus: "Sell BTC",
    date: "14 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 BTC",
    trandingClass: "trending-down",
  },
  {
    amount: "$116.89",
    companyStatus: "Sell ETH",
    date: "25 Mar, 2022",
    iconClass: "font-danger me-2",
    number: "0.089 ETH",
    trandingClass: "trending-down",
  },
  {
    amount: "$236.89",
    companyStatus: "Sell LTC",
    date: "28 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 LTC",
    trandingClass: "trending-up",
  },
  {
    amount: "$29.89",
    companyStatus: "Sell LTC",
    date: "05 Apr, 2022",
    iconClass: "font-success me-2",
    number: "0.089 LTC",
    trandingClass: "trending-up",
  },
  {
    amount: "$236.89",
    companyStatus: "Sell BTC",
    date: "16 Apr, 2022",
    iconClass: "font-danger me-2",
    number: "0.012 BTC",
    trandingClass: "trending-down",
  },
  {
    amount: "$236.89",
    companyStatus: "Sell BTC",
    date: "14 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 BTC",
    trandingClass: "trending-down",
  },
  {
    amount: "$116.89",
    companyStatus: "Sell ETH",
    date: "25 Mar, 2022",
    iconClass: "font-danger me-2",
    number: "0.089 ETH",
    trandingClass: "trending-down",
  },
  {
    amount: "$236.89",
    companyStatus: "Sell LTC",
    date: "28 Mar, 2022",
    iconClass: "font-success me-2",
    number: "0.018 LTC",
    trandingClass: "trending-up",
  },
]

export const currencies = [
  {
    Change: "5.90",
    changeCurrency: "change-currency font-success",
    coinName: "Bitcoin",
    CurremcyClass: "currency-icon warning",
    datafeather: "trending-up",
    price: "$13,098.09",
    svgIcon: "beta",
    totalBalance: "$74,871.470",
    totalCoin: "1.09634721",
  },
  {
    Change: "2.90",
    changeCurrency: "change-currency font-secondary",
    coinName: "Litecoin",
    CurremcyClass: "currency-icon success",
    datafeather: "trending-down",
    price: "$11,098.04",
    svgIcon: "ltc",
    totalBalance: "$87,897.098",
    totalCoin: "1.09675432",
  },
  {
    Change: "0.12",
    changeCurrency: "change-currency font-success",
    coinName: "Eathereum",
    CurremcyClass: "currency-icon primary",
    datafeather: "trending-up",
    price: "$45,198.09",
    svgIcon: "eth",
    totalBalance: "$45,178.010",
    totalCoin: "1.41557127",
  },
  {
    Change: "3.56",
    changeCurrency: "change-currency font-success",
    coinName: "Binance",
    CurremcyClass: "currency-icon secondary",
    datafeather: "trending-up",
    price: "$35,098.34",
    svgIcon: "bin",
    totalBalance: "$64,100.066",
    totalCoin: "1.78142254",
  },
  {
    Change: "1.23",
    changeCurrency: "change-currency font-secondary",
    coinName: "Tether",
    CurremcyClass: "currency-icon dark-green",
    datafeather: "trending-down",
    price: "$56,898.91",
    svgIcon: "te",
    totalBalance: "$61,574.218",
    totalCoin: "1.574215",
  },
]

export const portfolios = [
  {
    coinName: "Bitcoin",
    CurremcyClass: "currency-icon warning",
    price: "$14,987.13",
    priceClass: "font-success",
    status: "BTC",
    statusClass: "status bg-success",
    svgIcon: "beta",
    totalCoin: "BTC 0.00876543",
  },
  {
    coinName: "Ethereum",
    CurremcyClass: "currency-icon success",
    price: "$49,987.13",
    priceClass: "font-danger",
    status: "ETH",
    statusClass: "status bg-danger",
    svgIcon: "ltc",
    totalCoin: "ETC 1.60876543",
  },
  {
    coinName: "Litecoin",
    CurremcyClass: "currency-icon primary",
    price: "$35,571.25",
    priceClass: "font-success",
    status: "LTC",
    statusClass: "status bg-success",
    svgIcon: "eth",
    totalCoin: "LTC 1.60876543",
  },
  {
    coinName: "Dash",
    CurremcyClass: "currency-icon light-blue",
    price: "$17,047.30",
    priceClass: "font-success",
    status: "DSH",
    statusClass: "status bg-success",
    svgIcon: "dash",
    totalCoin: "DSH 1.80741510",
  },
]
