import { defineStore } from "pinia"
import { ref, computed } from "vue"
import { useLoader } from "@/composables/useLoader"
import {
  getFiles,
  getShipment,
  getTimeline,
  downloadFile as download,
} from "../service/trackingService"
import { toast } from "vue3-toastify"
import { useI18n } from "vue-i18n"
import { formatDate } from "@/utils/formatDate"


const { incrementRequisition, decrementRequisition } = useLoader()

export const detailsShipmentStore = defineStore("detailsShipmentStore", () => {
  const { t } = useI18n()

  // States
  const shipment = ref(null)
  const timeline = ref(null)
  const files = ref(null)

  // Computed

  const timelineFormated = computed(() => {
    if (!timeline.value) return []

    return timeline.value.map((item) => {


      const information = item.information ?? null
      const informationDate = information ? information.split("-")[0] : null
      const date = item.dateStatus ? formatDate(item.dateStatus) : informationDate ? informationDate : null

      return {
        date,
        description: item.modalStatus.description,
        checked: !!date,
      }
    })
  })

  const filesFormated = computed(() => {
    if (!files.value) return []
    return files.value.map((file) => {
      return {
        name: file.filename ? file.filename : null,
        date: file.dateOfAttachment ? file.dateOfAttachment : null,
        ...file,
      }
    })
  })

  // Methods
  const fetchShipment = async (params) => {
    if (!params || !params.refGotrans) return
    try {
      incrementRequisition()
      const response = await getShipment(params.refGotrans)
      shipment.value = response.data
      fetchTimeline(params.shipmentId)
      fetchFiles(params.shipmentId)
    } catch (error) {
      const response = { error }
      toast.error(error)
      return response
    } finally {
      decrementRequisition()
    }
  }

  const fetchTimeline = async (shipmentID) => {
    try {
      incrementRequisition()
      const response = await getTimeline(shipmentID)
      timeline.value = response.data
    } catch (error) {
      const response = { error }
      toast.error(error)
      return response
    } finally {
      decrementRequisition()
    }
  }

  const fetchFiles = async (shipmentID) => {
    try {
      incrementRequisition()
      const response = await getFiles(shipmentID)
      files.value = response.data
    } catch (error) {
      const response = { error }
      toast.error(error)
      return response
    } finally {
      decrementRequisition()
    }
  }

  const downloadFile = async (path, name) => {
    try {
      incrementRequisition()
      await download(path, name)
      toast.success(t("downloadSuccess"))
    } catch (error) {
      toast.error(t("downloadFailed"), error)
    } finally {
      decrementRequisition()
    }
  }

  const resetStore = () => {
    shipment.value = null
    timeline.value = null
    files.value = null
  }

  return {
    shipment,
    timeline,
    files,
    filesFormated,
    timelineFormated,
    fetchShipment,
    fetchTimeline,
    fetchFiles,
    downloadFile,
    resetStore,
  }
})
