export default {
  about: "About",
  accountLocked: "Your account is locked. Please contact support.",
  actionFailed: "The action could not be completed.",
  add: "Add",
  address: "Address",
  adminPanel: "Admin Panel",
  airline: 'Airline',
  airport: "Airport",
  airportOfDestiny: "Airport of Destiny",
  airportOfOrigin: "Airport of Origin",
  ata: "ATA",
  attachments: "Attachments",
  back: "Back",
  backToHomePage: "Back to Home Page",
  billing: "Billing",
  billingCycle: "Billing Cycle",
  booking: "Booking",
  cancel: "Cancel",
  cancelSubscription: "Cancel Subscription",
  changesSaved: "Your changes have been saved.",
  city: "City",
  clear: "Clear",
  client: "Client",
  close: "Close",
  comercialName: "Comercial Name",
  company: "Company",
  compose: "Compose",
  confirm: "Confirm",
  confirmPassword: "Confirm Password",
  consignee: "Consignee",
  contact: "Contact",
  country: "Country",
  countryOfDestination: "Country of Destination",
  countryOfDestiny: "Country of Destiny",
  countryOfOrigin: "Country of Origin",
  creationDate: "Creation Date",
  creditCard: "Credit Card",
  dashboard: "Dashboard",
  dateRange: "Date Range",
  delete: "Delete",
  destination: "Destination",
  downgradePlan: "Downgrade Plan",
  download: "Download",
  downloadFailed: "Download failed",
  downloadSuccess: "Download successful",
  draft: "Draft",
  edit: "Edit",
  email: "Email",
  emailIsRequired: "Email is required.",
  endDate: "End Date",
  enterAValidEmail: "Please enter a valid email.",
  error: "An error occurred. Please try again later.",
  eta: "ETA",
  etaPredictive: "Predictive ETA",
  etd: "ETD",
  export: "Export",
  failedSearchShipmentMovements: "We didn't find any Shipments linked to your user.",
  filter: "Filter",
  filters: "Filters",
  finalEta: "Final ETA",
  forgotPassword: "Forgot Password?",
  forward: "Forward",
  from: "From",
  help: "Help",
  home: "Home",
  house: "House",
  import: "Import",
  inbox: "Inbox",
  invalidCredentials: "Invalid username or password.",
  invalidEmail: "Please enter a valid email.",
  invalidPhone: "Please enter a valid phone number.",
  invoice: "Invoice",
  invoices: "Invoices",
  jobTitle: "Job Title",
  language: "Language",
  loading: "Loading, please wait...",
  loadMore: "Load More",
  login: "Login",
  logOut: "Log Out",
  logout: "Logout",
  markAsRead: "Mark as Read",
  markAsUnread: "Mark as Unread",
  master: "Master",
  maxLength: "Must be less than {count} characters long.",
  mbl: "MBL",
  message: "Message",
  minLength: "Must be at least {count} characters long.",
  modal: "Modal",
  month: "Month",
  moreFilters: "More Filters",
  name: "Name",
  next: "Next",
  nextBillingDate: "Next Billing Date",
  noEmails: "No emails to display.",
  noResults: "No results found.",
  notifications: "Notifications",
  open: "Open",
  optional: "(Optional)",
  origin: "Origin",
  password: "Password",
  passwordIsRequired: "Password is required.",
  passwordsMismatch: "Passwords do not match.",
  paymentFailed: "Payment failed, please try again.",
  paymentMethod: "Payment Method",
  paymentSuccessful: "Payment successful",
  phone: "Phone",
  port: "Port",
  portOfDestiny: "Port of Destiny",
  portOfOrigin: "Port of Origin",
  postalCode: "Postal Code",
  profile: "Profile",
  refClient: "Ref. Client",
  reference: "Reference",
  refGotrans: 'Ref. Go-Trans',
  register: "Register",
  reject: "Reject",
  reply: "Reply",
  required: "* Required",
  requiredField: "This field is required.",
  resetPassword: "Reset Password",
  result: "Result",
  retry: "Retry",
  save: "Save",
  search: "Search",
  selectDate: "Select Date",
  send: "Send",
  sent: "Sent",
  sessionExpired: "Your session has expired. Please log in again.",
  settings: "Settings",
  shipIMO: "Ship IMO",
  shipment: "Shipment",
  shipName: "Ship Name",
  shipowner: "Shipowner",
  shipper: "Shipper",
  show: "Show",
  signInWithFacebook: "Sign in with Facebook",
  signInWithGoogle: "Sign in with Google",
  simpleSearch: "Simple Search",
  sorryYouDontHaveAccessToThisPage: "Sorry, you don't have access to this page.",
  startDate: "Start Date",
  state: "State",
  status: "Status",
  subject: "Subject",
  submit: "Submit",
  submitForm: "Submit Form",
  subscriptionPlan: "Subscription Plan",
  subscriptions: "Subscriptions",
  success: "Operation completed successfully.",
  termsAcceptance: "You must accept the terms and conditions.",
  timeline: "Timeline",
  to: "To",
  today: "Today",
  tomorrow: "Tomorrow",
  trackShipmentMovements: "Track shipment movements",
  trash: "Trash",
  travelNumber: "Travel Number",
  update: "Update",
  upgradePlan: "Upgrade Plan",
  upload: "Upload",
  userManagement: "User Management",
  username: "Username",
  week: "Week",
  welcome: "Welcome, {username}!",
  year: "Year",
  yesterday: "Yesterday",
}
