<template>
  <head>
    <title>Portal Go Trans</title>
  </head>
  <div
    v-if="loading"
    class="loader-wrapper"
    style="background-color: rgba(255, 255, 255, 0.8) !important"
  >
    <div class="loader-index"><span></span></div>
    <svg>
      <defs></defs>
      <filter id="goo">
        <fegaussianblur
          in="SourceGraphic"
          stddeviation="11"
          result="blur"
        ></fegaussianblur>
        <fecolormatrix
          in="blur"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
          result="goo"
        ></fecolormatrix>
      </filter>
    </svg>
  </div>
  <router-view></router-view>
</template>

<script>
import { useLoader } from "./composables/useLoader"

export default {
  name: "App",
  data() {
    return {
      loadingLocal: true,
    }
  },
  computed: {
    loading() {
      const { loading } = useLoader()
      return this.loadingLocal || loading.value
    },
  },
  watch: {
    $route() {
      setTimeout(() => {
        this.loadingLocal = !this.loadingLocal
      }, 1000)
      this.loadingLocal = !this.loadingLocal
    },
  },
  mounted() {
    this.loadingLocal = false
  },
}
</script>
