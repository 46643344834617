export const activirtyHours = [
  {
    inNumber: "30",
    inPercentage: "140%",
    title: "Time Spent",
  },
  {
    inNumber: "45",
    inPercentage: "86%",
    title: "Lessons taken",
  },
  {
    inNumber: "12",
    inPercentage: "120%",
    title: "Exams passed",
  },
]

export const courses = [
  {
    backArrow: require("@/assets/images/dashboard-3/course/back-arrow/1.png"),
    iconWrap: require("@/assets/images/dashboard-3/course/1.svg"),
    title: "Management",
  },
  {
    backArrow: require("@/assets/images/dashboard-3/course/back-arrow/2.png"),
    iconWrap: require("@/assets/images/dashboard-3/course/2.svg"),
    title: "Web Devlopment",
  },
  {
    backArrow: require("@/assets/images/dashboard-3/course/back-arrow/1.png"),
    iconWrap: require("@/assets/images/dashboard-3/course/3.svg"),
    title: "Business Analytics",
  },
  {
    backArrow: require("@/assets/images/dashboard-3/course/back-arrow/3.png"),
    iconWrap: require("@/assets/images/dashboard-3/course/4.svg"),
    title: "Marketing",
  },
]

export const upcomingSchedule = [
  {
    backArrow: require("@/assets/images/dashboard-3/course/back-arrow/1.png"),
    iconWrap: require("@/assets/images/dashboard-3/course/1.svg"),
    title: "Management",
  },
  {
    backArrow: require("@/assets/images/dashboard-3/course/back-arrow/2.png"),
    iconWrap: require("@/assets/images/dashboard-3/course/2.svg"),
    title: "Web Devlopment",
  },
  {
    backArrow: require("@/assets/images/dashboard-3/course/back-arrow/1.png"),
    iconWrap: require("@/assets/images/dashboard-3/course/3.svg"),
    title: "Business Analytics",
  },
  {
    backArrow: require("@/assets/images/dashboard-3/course/back-arrow/3.png"),
    iconWrap: require("@/assets/images/dashboard-3/course/4.svg"),
    title: "Marketing",
  },
]
