<template>
  <div class="col-xl-12 col-md-6">
    <div class="card mobile-app-card">
      <div class="card-header card-no-border pb-0">
        <h5 class="mb-3">
          <span class="f-16 f-light">Have you Tried Our </span>Mobile
          Application?
        </h5>
        <button
          class="purchase-btn btn btn-primary btn-hover-effect f-w-500"
          type="button"
        >
          Try Now
        </button>
      </div>
      <div class="card-body p-0 text-end">
        <img
          class="wavy"
          src="@/assets/images/dashboard-5/wave.png"
          alt=""
        /><img
          src="@/assets/images/dashboard-5/mobile-img.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
