<template>
  <div class="header-logo-wrapper col-auto p-0">
    <div class="logo-wrapper bg-custom-logo">
      <router-link to="/">
        <img
          class="img-fluid"
          src="../../assets/images/logo/logo.png"
          alt
        />
      </router-link>
    </div>
    <div
      class="toggle-sidebar"
      @click="toggle_sidebar"
    >
      <vue-feather
        id="sidebar-toggle"
        class="status_toggle middle sidebar-toggle icon-white"
        type="align-center"
        fill="white"
      ></vue-feather>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logo",
  methods: {
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar")
    },
  },
}
</script>

<style scoped>
.icon-white {
  color: white !important;
}

.bg-custom-logo {
  background-color: #222348de !important;
}
</style>
