<template>
  <div class="translate_wrapper pt-1">
    <div>
      <li class="onhover-dropdown">
        <div class="current_lang">
          <div class="lang">
            <i
              class="flag-icon"
              :class="langIcon"
              @mouseover="display()"
            ></i><span class="lang-txt">{{ langLangauge }}</span>
          </div>
        </div>
        <ul
          class="profile-dropdown onhover-show-div pa-0"
          style="top: 37px !important; padding: 0 !important"
        >
          <li
            v-for="(lang, index) in localOptions"
            :key="index"
          >
            <div class="lang">
              <i
                class="flag-icon"
                :class="lang.icon"
              >
              </i>
              <span
                class="lang-txt"
                @click.prevent="changeLocale(lang, lang.id)"
              >
                {{ toUpperCase(lang.name) }}
                <span>
                  {{ lang.short }}
                </span>
              </span>
            </div>
          </li>
        </ul>
      </li>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { localeOptions } from "../../constants/config"

export default {
  name: "LanguagePage",
  data() {
    return {
      isActive: false,
      localOptions: localeOptions,
    }
  },
  computed: {
    ...mapGetters({
      langIcon: "langIcon",
      langLangauge: "langLangauge",
    }),
  },
  created() {
    const currentLanguage = localStorage.getItem("currentLanguage") || "en"
    const currentLanguageIcon =
      localStorage.getItem("currentLanguageIcon") || "flag-icon-us"

    this.$store.dispatch("setLang", {
      name: currentLanguage,
      icon: currentLanguageIcon,
    })
    this.$i18n.locale = currentLanguage
  },

  methods: {
    display() {
      this.isActive = !this.isActive
    },
    changeLocale(value, language) {
      this.$i18n.locale = language
      this.$store.dispatch("setLang", value)
      this.isActive = false
    },
    toUpperCase(str) {
      return str ? str.toUpperCase() : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-dropdown {
  :hover {
    background-color: var(--theme-deafult);
  }
}
</style>
