import products from "../../data/product.json"
import productswiper from "../../data/productswiper.json"
const cartItems = []

const state = {
  cart: JSON.parse(localStorage.getItem("cartItem")) || cartItems,
  col2: false,
  col3: false,
  col4: true,
  col6: false,
  filteredProduct: [],
  list: false,
  listViewEnable: false,
  order: [],
  price: [],
  products: products.data,
  searchTerm: "",
  swiper: productswiper.swiper,
  swiper1: productswiper.swiper1,
  tagItems: [],
}
const getters = {
  col2: (state) => {
    return state.col2
  },
  col3: (state) => {
    return state.col3
  },
  col4: (state) => {
    return state.col4
  },
  col6: (state) => {
    return state.col6
  },
  filterProducts: (state) => {
    return state.filteredProduct.filter((product) => {
      if (!state.tagItems.length) return true
      const Tags = state.tagItems.some((prev) => {
        if (product.tags) {
          if (product.tags.includes(prev)) {
            return prev
          }
        }
      })
      return Tags
    })
  },
  getBrands: (state) => {
    const brands = [...new Set(state.products.map((product) => product.brand))]
    return brands
  },

  getCategory: (state) => {
    var uniqueCategory = []
    var itemCat = Array()
    state.products.map((product) => {
      if (product.category) {
        product.category.map((category) => {
          const index = uniqueCategory.indexOf(category)
          if (index === -1) uniqueCategory.push(category)
        })
      }
    })
    for (var i = 0; i < uniqueCategory.length; i++) {
      itemCat.push({ category: uniqueCategory[i] })
    }
    return itemCat
  },
  getColors: (state) => {
    var uniqueColors = []
    var itemColor = Array()
    state.products.map((product) => {
      if (product.colors) {
        product.colors.map((color) => {
          const index = uniqueColors.indexOf(color)
          if (index === -1) uniqueColors.push(color)
        })
      }
    })
    for (var i = 0; i < uniqueColors.length; i++) {
      itemColor.push({ color: uniqueColors[i] })
    }
    return itemColor
  },
  getOrder: (state) => {
    return state.order
  },
  getTotalAmount: (state) => {
    return state.cart.reduce((prev, curr) => {
      return prev + curr.price * curr.quantity
    }, 0)
  },

  listViewEnable: (state) => {
    return state.listViewEnable
  },
  price: (state) => {
    return state.products.map((product) => {
      if (product.price) {
        return product.price
      }
    })
  },
}

const mutations = {
  addToCart: (state, payload) => {
    let hasItems = state.cart.find((items) => {
      if (items.id === payload.id) {
        items.quantity = items.quantity ? items.quantity : 1
        return true
      }
      return false
    })
    if (!hasItems) {
      state.cart.push(payload)
    }
    localStorage.setItem("cartItem", JSON.stringify(state.cart))
  },
  createOrder: (state, payload) => {
    state.order = payload
  },
  grid2: (state, payload) => {
    state.col2 = payload
    state.col3 = false
    state.col4 = false
    state.col6 = false
    state.listViewEnable = false
  },
  grid3(state) {
    state.col2 = false
    state.col3 = true
    state.col4 = false
    state.col6 = false
    state.listViewEnable = false
  },
  grid4(state) {
    state.col2 = false
    state.col3 = false
    state.col4 = true
    state.col6 = false
    state.listViewEnable = false
  },
  grid6(state) {
    state.col2 = false
    state.col3 = false
    state.col4 = false
    state.col6 = true
    state.listViewEnable = false
  },
  gridView(state) {
    state.listViewEnable = false
    state.col4 = true
  },

  listView(state) {
    state.listViewEnable = true
    state.list = true
    state.col2 = false
    state.col3 = false
    state.col4 = false
    state.col6 = false
  },

  priceFilter: (state, payload) => {
    state.filteredProduct = []
    state.products.forEach((product) => {
      if (product.price >= payload.min && product.price <= payload.max) {
        state.filteredProduct.push(product)
      }
    })
  },

  removeProduct: (state, payload) => {
    const index = state.cart.indexOf(payload)
    state.cart.splice(index, 1)
    localStorage.setItem("cartItem", JSON.stringify(state.cart))
  },

  setTags: (state, payload) => {
    state.tagItems = payload
  },

  sortProducts: (state, payload) => {
    if (payload === "a-z") {
      state.filteredProduct.sort(function (a, b) {
        if (a.name < b.name) {
          return -1
        } else if (a.name > b.name) {
          return 1
        }
        return 0
      })
    }

    if (payload === "low") {
      state.filteredProduct.sort(function (a, b) {
        if (a.price < b.price) {
          return -1
        } else if (a.price > b.price) {
          return 1
        }
        return 0
      })
    } else if (payload === "high") {
      state.filteredProduct.sort(function (a, b) {
        if (a.price > b.price) {
          return -1
        } else if (a.price < b.price) {
          return 1
        }
        return 0
      })
    }
  },

  updateCartQuantity: (state, payload) => {
    function calculateStockCounts(product, quantity) {
      let qty = product.quantity + quantity
      let stock = product.stock
      if (stock < qty) {
        return false
      }
      return true
    }
    state.cart.find((items, index) => {
      if (items.id === payload.product.id) {
        let qty = state.cart[index].quantity + payload.qty
        let stock = calculateStockCounts(state.cart[index], payload.qty)
        if (qty !== 0 && stock) state.cart[index]["quantity"] = qty

        localStorage.setItem("cartItem", JSON.stringify(state.cart))
        return true
      }
    })
  },
}

const actions = {
  addClass: (context, payload) => {
    context.commit("addClass", payload)
  },
  addToCart: (context, payload) => {
    context.commit("addToCart", payload)
  },
  Categoryfilter: (context, payload) => {
    context.commit("Categoryfilter", payload)
  },
  createOrder: (context, payload) => {
    context.commit("createOrder", payload)
  },
  priceFilter: (context, payload) => {
    context.commit("priceFilter", payload)
  },
  removeProduct: (context, payload) => {
    context.commit("removeProduct", payload)
  },
  setTags: (context, payload) => {
    context.commit("setTags", payload)
  },
  sortProducts: (context, payload) => {
    context.commit("sortProducts", payload)
  },
  updateCartQuantity: (context, payload) => {
    context.commit("updateCartQuantity", payload)
  },
}

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
