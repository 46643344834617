<template>
  <b-card class="card-style">
    <div>
      <b-row>
        <i class="fa fa-map-marker"></i>
        <div class="dotted-border-left">
          <b-row class="ml-1">
            <b-text-view
              :label="`${$t('countryOfOrigin')}:`"
              :text="shipment?.origin?.country?.nameCountry"
            />
          </b-row>
          <b-row
            v-if="portOrAirportOrigin"
            class="ml-1"
          >
            <b-text-view
              :label="`${$t(portOrAirportOrigin)}:`"
              :text="shipment?.origin?.namePort"
            />
          </b-row>
          <b-row class="ml-1">
            <b-text-view
              :label="`${$t('countryOfDestiny')}:`"
              :text="shipment?.destiny?.country?.nameCountry"
            />
          </b-row>
          <b-row
            v-if="portOrAirportDestiny"
            class="ml-1"
          >
            <b-text-view
              :label="`${$t(portOrAirportDestiny)}:`"
              :text="shipment?.destiny?.namePort"
            />
          </b-row>
        </div>
        <i class="fa fa-map-marker"></i>
      </b-row>
      <div class="ship pt-3">
        <b-row
          v-for="(item, index) in textItems"
          :key="index"
        >
          <b-text-view
            :label="`${$t(item.label)}:`"
            :text="format(item.text, shipment)"
          />
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script setup>
import { defineProps } from "vue"
import { formatDate } from "@/utils/formatDate"
import { computed } from "vue"

const props = defineProps({
  shipment: {
    type: Object,
  },
})

const textItemsDefault = [
  {
    label: "ETD",
    text: "etd",
  },
  {
    label: "ETA",
    text: "eta",
  },
  {
    label: "finalEta",
    text: "finalEta",
  },
]

const textItemsCMICME = [
  {
    label: "ETD",
    text: "etd",
  },
  {
    label: "ETA",
    text: "eta",
  },
  {
    label: "finalEta",
    text: "finalEta",
  },
  {
    label: "Ship Name",
    text: "shipName",
  },
]

const textItemsCAICAE = [
  {
    label: "ETD",
    text: "etd",
  },
  {
    label: "ETA",
    text: "eta",
  },
  {
    label: "finalEta",
    text: "finalEta",
  },
  {
    label: "airline",
    text: "airline",
  },
  {
    label: "travelNumber",
    text: "travelNumber",
  },
]

const textItems = computed(() => {
  const shipmentModal = props.shipment?.shipmentModal
  if (shipmentModal === "CMI" || shipmentModal === "CME") return textItemsCMICME
  if (shipmentModal === "CAI" || shipmentModal === "CAE") return textItemsCAICAE
  return textItemsDefault
})

const portOrAirportOrigin = computed(() => {
  const shipmentModal = props.shipment?.shipmentModal
  if (shipmentModal === "CMI" || shipmentModal === "CME") return 'portOfOrigin'
  if (shipmentModal === "CAI" || shipmentModal === "CAE") return 'airportOfOrigin'
  return null
})

const portOrAirportDestiny = computed(() => {
  const shipmentModal = props.shipment?.shipmentModal
  if (shipmentModal === "CMI" || shipmentModal === "CME") return 'portOfDestiny'
  if (shipmentModal === "CAI" || shipmentModal === "CAE") return 'airportOfDestiny'
  return null
})

const format = (key, item) => {
  if (key === "etd" || key === "eta" || key === "finalEta")
    return item?.[key] ? formatDate(item[key]) : "-"
  if (key === "shipName") return item?.ship?.name || "-"
  if (key === "airline") return item?.carrierContact?.nameCg || "-"

  return item?.[key] || "-"
}
</script>

<style scoped>
.card-style {
  min-width: 196px;
}

i {
  padding: 0;
  color: var(--theme-deafult);
}

.dotted-border-left {
  border-left: 2px dotted var(--theme-deafult);
  margin-left: 7px;
  min-height: 77px;
  min-width: 174px;
}

.ship .b-row {
  min-height: 51px;
}
</style>
