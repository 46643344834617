<template>
  <div class="col-xxl-3 col-xl-4 col-sm-6 box-col-6 wow zoomIn">
    <div class="card purchase-card discover">
      <img
        class="img-fluid"
        src="@/assets/images/dashboard-2/discover.png"
        alt="vector discover"
      />
      <div class="card-body pt-3">
        <h5 class="mb-1">Discover Pro</h5>
        <p class="f-light">
          Design and style should work toward making you look good.
        </p>
        <a
          class="purchase-btn btn btn-hover-effect btn-primary f-w-500"
          href="https://1.envato.market/3GVzd"
          target="_blank"
        >Update Now</a>
      </div>
    </div>
  </div>
</template>
