// src/stores/authStore.js
import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { loginService, logoutService } from "../service/authService";
import router from "@/router";
import { toast } from "vue3-toastify";
import { useLoader } from "@/composables/useLoader";
import {
  decodeToken,
  clearAuthData,
  isTokenExpired
} from "@/utils/jwt";
import Cookies from "js-cookie";
import { useI18n } from "vue-i18n";



export const useAuthStore = defineStore("authStore", () => {
  const { t } = useI18n();

  const username = ref("");
  const password = ref("");

  const { incrementRequisition, decrementRequisition } = useLoader();

  const userInfo = computed(() => {
    const accessToken = Cookies.get("accessToken");
    return accessToken ? decodeToken(accessToken) : null;
  });

  const login = async (user) => {
    incrementRequisition();
    try {
      const response = await loginService(user);
      if (response?.status !== 200) {
        toast.error("Invalid credentials.");
        return;
      }
      router.push("/");
    } catch (error) {
      toast.error(t("invalidCredentials"));
    } finally {
      decrementRequisition();
    }
  };

  const logout = () => {
    logoutService();
    clearAuthData();
    router.push("/login");
  };

  return {
    username,
    password,
    userInfo,
    login,
    logout,
    isTokenExpired
  };
});
