import { format, parseISO, addHours } from "date-fns"
import { enUS, ptBR } from "date-fns/locale"

/**
 * Formats an ISO date string into yyyy-MM-dd or dd/MM/yyyy based on the locale
 * @param {string} isoDate - The ISO date string to format
 * @param {string} locale - The locale ('en' or 'pt')
 * @returns {string} - The formatted date
 */

export function formatDate(isoDate, locale = "pt") {
  const localesMap = {
    en: enUS,
    pt: ptBR,
  }

  if (!isoDate) return

  const currentLocale = localesMap[locale] || enUS
  const parsedDate = parseISO(isoDate)

  return format(addHours(parsedDate, 3), locale === "en" ? "yyyy-MM-dd" : "dd/MM/yyyy", {
    locale: currentLocale,
  })
}
