<template>
  <div
    id="pageWrapper"
    class="page-wrapper compact-wrapper"
  >
    <div class="error-wrapper">
      <div class="container">
        <img
          class="img-100"
          src="@/assets/images/other-images/sad.png"
          alt=""
        />
        <div class="error-heading">
          <h2 class="headline font-warning">401</h2>
        </div>
        <div class="col-md-8 offset-md-2">
          <p class="sub-content">{{ $t('sorryYouDontHaveAccessToThisPage') }}</p>
        </div>
        <div>
          <router-link
            class="btn btn-warning-gradien btn-lg"
            to="/"
          >
            {{ $t('backToHomePage') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
