<template>
  <div
    v-if="!loading"
    class="div-report"
  >
    <div v-if="embedUrl">
      <iframe
        :src="embedUrl"
        width="100%"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
    <div v-else>
      <Error401 />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue"
import { useLoader } from "@/composables/useLoader"
import { getShipmentOngoinReports } from "./service/powerEmbeddedService"
import { toast } from "vue3-toastify"
import { useAuthStore } from "@/auth/store/authStore"
import { storeToRefs } from "pinia"
import Error401 from "../error/error401.vue"
import { useI18n } from "vue-i18n"

const { t } = useI18n()

const { incrementRequisition, decrementRequisition, loading } = useLoader()

const embedUrl = ref(null)

const store = useAuthStore()
const { userInfo } = storeToRefs(store)

onMounted(async () => {
  try {
    incrementRequisition()
    await getReport()
  } catch (error) {
    toast.error(t("sorryYouDontHaveAccessToThisPage"))
  } finally {
    decrementRequisition()
  }
})

const getReport = async () => {
  const response = await getShipmentOngoinReports(userInfo.value.email)

  embedUrl.value = response?.data?.embedUrl
}
</script>

<style>
iframe {
  height: 100vh;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.div-report {
  width: 100%;
  height: 100%;
}
</style>
