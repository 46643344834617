<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div class="login-main">
                <div class="d-flex justify-content-center mb-4 logo-custom">
                  <img
                    class="img-fluid"
                    src="@/assets/images/logo/logo.jpg"
                    alt
                  />
                </div>
                <form
                  class="theme-form"
                  @submit.prevent="loginAuth"
                >
                  <h4>Sign in to account</h4>
                  <p>Enter your email & password to login</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input
                      v-model="username"
                      class="form-control"
                      type="email"
                      required
                      placeholder="Test@gmail.com"
                    />
                    <span
                      v-if="emailError"
                      class="validate-error"
                    >
                      {{ emailError }}
                    </span>
                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input
                        v-model="password"
                        class="form-control"
                        type="password"
                        required
                        placeholder="*********"
                      />
                      <span
                        v-if="passwordError"
                        class="validate-error"
                      >
                        {{ passwordError }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <!-- <div class="checkbox p-0">
                      <input
                        id="checkbox1"
                        type="checkbox"
                      />
                      <label
                        class="text-muted"
                        for="checkbox1"
                      >
                        Remember password
                      </label>
                    </div> -->
                    <!-- <router-link
                      class="link"
                      to="/authentication/forgetpassword"
                    >
                      Forgot password?
                    </router-link> -->
                    <div class="text-end mt-3">
                      <button
                        class="btn btn-primary btn-block w-100"
                        type="submit"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue"
import { useAuthStore } from "./store/authStore"
import { storeToRefs } from "pinia"
import { useI18n } from "vue-i18n"

const authStore = useAuthStore()

const { username, password } = storeToRefs(authStore)

const { login } = authStore

const { t } = useI18n()

const user = computed(() => {
  return {
    username: username.value,
    password: password.value,
  }
})

const emailError = computed(() =>
  !user.value?.username
    ? t("emailIsRequired")
    : !validEmail(user.value?.username)
    ? t("enterAValidEmail")
    : ""
)

const passwordError = computed(() =>
  user.value?.password.length < 1
    ? t("passwordIsRequired")
    : ""
)

function validEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[^0-9]{1,3}\.[^0-9]{1,3}]+)|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

async function loginAuth() {
  await login(user.value)
}
</script>

<style scoped>
.validate-error {
  color: red;
  font-size: 0.875rem;
}

.logo-custom {
  height: 160px;
}
</style>
