import { createStore } from "vuex"

import layout from "./modules/layout"
import menu from "./modules/menu"
import chat from "./modules/chat"
import product from "./modules/product"
import todo from "./modules/todo"
import contact from "./modules/contact"
import jobs from "./modules/jobs"
import courses from "./modules/courses"
import common from "./modules/common"
import tags from "./modules/tags"
import bootsrap from "./modules/bootsrap"
import language from "./modules/language"

export default createStore({
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload)
    },
  },
  getters: {
    langIcon: (state) => {
      return state.langIcon
    },
    langLangauge: (state) => {
      return state.langLangauge
    },
  },
  modules: {
    alert,
    bootsrap,
    chat,
    common,
    contact,
    courses,
    jobs,
    language,
    layout,
    menu,
    product,
    tags,
    todo,
  },
  mutations: {
    change(state) {
      state.isActive = !state.isActive
    },
    changeLang(state, payload) {

      if (payload?.name && payload?.icon) {
        localStorage.setItem("currentLanguage", payload.name)
        localStorage.setItem("currentLanguageIcon", payload.icon)
        state.langIcon = payload.icon
        state.langLangauge = payload.name
      } else {
        state.langIcon = "flag-icon-us"
        state.langLangauge = "EN"
        localStorage.setItem("currentLanguage", "EN")
        localStorage.setItem("currentLanguageIcon", "flag-icon-us")
      }
    },
  },
  state: {
    isActive: false,
    langIcon: "",
    langLangauge: ""
  },
})
