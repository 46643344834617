<template>
  <b-card :title="title">
    <div class="tabbed-card">
      <ul
        class="pull-right nav nav-pills nav-primary"
        role="tablist"
      >
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          class="nav-item"
          @click="activeTab = tab.name"
        >
          <a
            class="nav-link"
            :class="{ 'show active': activeTab === tab.name }"
            data-bs-toggle="pill"
            role="tab"
            style="cursor: pointer;"
            :aria-controls="`pills-${tab.name}`"
            :aria-selected="activeTab === tab.name"
          >
            <i :class="tab.icon"></i> {{ tab.label }}
          </a>
        </li>
      </ul>
      <div class="tab-content">
        <div
          v-for="(tab, index) in tabs"
          :id="`pills-${tab.name}`"
          :key="index"
          class="tab-pane fade"
          :class="{ 'show active': activeTab === tab.name }"
          role="tabpanel"
        >
          <slot :name="tab.name"></slot>
        </div>
      </div>
    </div>
    <template #actions>
      <div v-if="$slots.actions">
        <div>
          <slot name="actions"></slot>
        </div>
      </div>
    </template>
  </b-card>
</template>

<script setup>
import { ref } from "vue"
import { defineProps } from "vue"

// Define props to make the component more reusable
const props = defineProps({
  title: {
    type: String,
    default: "Tab With Icon",
  },
  tabs: {
    type: Array,
    default: () => [
      {
        name: "timeline",
        label: "Home",
        icon: "fa fa-home",
      },
      {
        name: "about",
        label: "Profile",
        icon: "fa fa-user",
      },
      {
        name: "friends",
        label: "Contact",
        icon: "fa fa-address-book",
      },
    ],
  },
})

// Reactive variable to track the active tab
const activeTab = ref(props.tabs[0].name)
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>
