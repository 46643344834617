<template>
  <b-card
    :title="title"
    :sub-title="subTitle"
  >
    <div
      v-if="files.length"
      class="file-list"
    >
      <div
        v-for="(file, index) in files"
        :key="index"
        class="file px-3 py-2 card"
      >
        <b-row class="d-flex align-items-center">
          <b-col class="col col-11">
            <b-row class="align-items-center">
              <vue-feather
                size="32"
                type="paperclip"
                class="f-light"
              />
              <div class="m-l-10">
                <div class="file-info">
                  <span class="file-name f-light">{{ file.name }}</span>
                </div>
                <div class="file-details">
                  <span class="file-date f-light">Date: {{ formatDate(file.date) }}</span>
                </div>
              </div>
            </b-row>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              shape="round"
              @click="onView(file)"
            >
              <vue-feather type="arrow-down-circle"></vue-feather>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
    <div
      v-else
      class="no-files f-light text-center py-3"
    >
      <span>No files available</span>
    </div>
  </b-card>
</template>

<script setup>
import { defineProps, defineEmits } from "vue"
import { formatDate } from "@/utils/formatDate"

const props = defineProps({
  title: {
    type: String,
    default: "Documents",
  },
  subTitle: {
    type: String,
    default: "",
  },
  files: {
    type: Array,
    default: () => [],
    validator: (files) =>
      files.every(
        (file) => typeof file.name === "string" && typeof file.date === "string"
      ),
  },
})

const emits = defineEmits(["view"])

const onView = (file) => emits("view", file)
</script>

<style scoped>
.file {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: none;
}

.file-name {
  font-weight: bold;
  font-size: 16px;
}

.file-details {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: var(--body-font-color);
}

.no-files {
  font-size: 16px;
}
</style>
