import { createI18n } from "vue-i18n"
import en from "./en"
import pt from "./pt"
import { defaultLocale, localeOptions } from "@/constants/config"

const locale =
    localStorage.getItem("currentLanguage") &&
        localeOptions.filter((x) => x.id === localStorage.getItem("currentLanguage"))
            .length > 0
        ? localStorage.getItem("currentLanguage")
        : defaultLocale

export const i18n = createI18n({
    legacy: false,
    locale: locale,
    messages: {
        en,
        pt,
    },
})