export const defaultLocale = "en"
export const localeOptions = [
  {
    icon: "flag-icon-us",
    id: "en",
    name: "en",
    short: "(US)"
  },
  {
    icon: "flag-icon-br",
    id: "pt",
    name: "pt",
    short: "(BR)"
  },
]
