import todo from "../../data/todo.json"

const state = {
  todo: todo.data,
}

const mutations = {
  addtodo: (state, payload) => {
    let todoID = []
    state.todo.forEach((element) => {
      todoID.push(element.id)
    })

    let id = Math.max(...todoID) + 1
    state.todo.unshift({
      badgeClass: "badge-light-danger",
      date: "16 Jan",
      delete: false,
      id: id,
      priority: "Pending",
      status: "incomplete",
      title: payload,
    })
  },
  alltaskcomplete: (state, payload) => {
    state.todo.filter(function (list) {
      return (list.status = payload === true ? "complete" : "incomplete")
    })
  },
  taskcomplete: (state, payload) => {
    state.todo.find(function (list) {
      if (list.id === payload) {
        return (list.status =
          list.status === "complete" ? "incomplete" : "complete")
      }
    })
  },
  tododelete: (state, payload) => {
    state.todo = state.todo.filter((list) => list.id !== payload)
  },
}
export default {
  mutations,
  namespaced: true,
  state,
}
