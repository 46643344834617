const state = {
  activeTab: "0",
  display: true,
  selectedUser: "",
}
const mutations = {
  active: (state, payload) => {
    state.activeTab = payload
  },

  change: (state) => {
    state.display = !state.display
  },
  print: (state, payload) => {
    state.selectedUser = payload
  },
}
const getters = {
  activeTab: (state) => {
    return state.activeTab
  },
  display: (state) => {
    return state.display
  },
  selectedUser: (state) => {
    return state.selectedUser
  },
}
const actions = {
  active: (context, payload) => {
    context.commit("active", payload)
  },
  change: (context) => {
    context.commit("change")
  },
}
export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
}
