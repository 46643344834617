import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import "@/assets/scss/app.scss"
import VueFeather from "vue-feather"
import store from "./store"
import Breadcrumbs from "./components/bread_crumbs"
import VueApexCharts from "vue3-apexcharts"
import "bootstrap"
import settingPage from "./components/settingPage.vue"
import DropZone from "dropzone-vue"
import "bootstrap/dist/js/bootstrap.bundle"
import Vue3EasyDataTable from "vue3-easy-data-table"
import "vue3-easy-data-table/dist/style.css"
import { PerfectScrollbarPlugin } from "vue3-perfect-scrollbar"
import "vue3-perfect-scrollbar/style.css"
import Toaster from "@meforma/vue-toaster"
import Vue3Tour from "vue3-tour"
import "vue3-tour/dist/vue3-tour.css"
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"
import bcard from "./components/b-card.vue"
import Vue3Toasity, { toast } from "vue3-toastify"
import "vue3-toastify/dist/index.css"
import VueAnimateOnScroll from "vue3-animate-onscroll"
import { quillEditor } from "vue3-quill"
import Lightbox from "vue-easy-lightbox"
import { VueMasonryPlugin } from "vue-masonry"
import vue3StarRatings from "vue3-star-ratings"
import Datepicker from "vue3-datepicker"
import VueNumber from "vue-number-animation"
import SimpleTypeahead from "vue3-simple-typeahead"
import "vue3-simple-typeahead/dist/vue3-simple-typeahead.css"
import Multiselect from "vue-multiselect"
import "vue-multiselect/dist/vue-multiselect.css"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import BCol from "./components/b-col.vue"
import BRow from "./components/b-row.vue"
import BButton from "./components/b-button.vue"
import "./assets/css/utils.css"
import BTextView from "./components/b-text-view.vue"
import pinia from "./plugins/pinia"
import Vue3Lottie from 'vue3-lottie'
import { i18n } from "./i18n"

const app = createApp(App)
  .use(Toast)
  .use(i18n)
  .use(SimpleTypeahead)
  .use(VueMasonryPlugin)
  .use(VueNumber)
  .component("vue3-star-ratings", vue3StarRatings)
  .use(quillEditor)
  .use(Lightbox)
  .use(store)
  .use(VueSweetalert2)
  .use(VueAnimateOnScroll)
  .use(Vue3Toasity)
  .use(Vue3Tour)
  .use(Toaster)
  .use(pinia)
  .use(router)
  .component("settingPage", settingPage)
  .component("b-card", bcard)
  .use(PerfectScrollbarPlugin)
  .use(DropZone)
  .component("multiselect", Multiselect)
  .component(VueFeather.name, VueFeather)
  .component("Breadcrumbs", Breadcrumbs)
  .component("apexchart", VueApexCharts)
  .component("EasyDataTable", Vue3EasyDataTable)
  .component("BCol", BCol)
  .component("BRow", BRow)
  .component("BButton", BButton)
  .component("BTextView", BTextView)
  .use(require("vue-chartist"))
  .component("Datepicker", Datepicker)
  .use(Vue3Lottie)

app.mount("#app")
