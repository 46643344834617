import { computed, ref } from 'vue'

const _requisitionCount = ref(0)
const _forceDisableLoading = ref(false)
const _forceEnableLoading = ref(false)

export function useLoader() {
  function incrementRequisition() {
    _requisitionCount.value = _requisitionCount.value + 1
  }

  function decrementRequisition() {
    if (_requisitionCount.value < 0) {
      _requisitionCount.value = 0
      return
    }
    _requisitionCount.value = _requisitionCount.value - 1
  }

  function startLoader() {
    _forceEnableLoading.value = true
  }

  function dismissLoader() {
    _forceEnableLoading.value = false
  }

  const loading = computed(() => {
    if (_forceEnableLoading.value) {
      return true
    }

    if (_forceDisableLoading.value) {
      return false
    }

    return _requisitionCount.value > 0
  })

  const statusLoader = computed(() => {
    return _requisitionCount.value
  })

  return {
    decrementRequisition,
    dismissLoader,
    incrementRequisition,
    loading,
    startLoader,
    statusLoader,
  }
}
