<template>
  <TabCard
    :title="t('filters')"
    :tabs="tabs"
  >
    <template #simpleSearch>
      <b-row>
        <b-col
          v-for="field in simpleSearchFields"
          :key="field.id"
        >
          <b-text-view
            class="form-label"
            :for="field.id"
            :label="`${t(field.label)}:`"
          />
          <input
            :id="field.id"
            v-model.trim="filters[field.model]"
            class="form-control"
            :type="field.type || 'text'"
          />
        </b-col>
      </b-row>
    </template>
    <template #moreFilters>
      <b-row class="mb-2">
        <b-col
          v-for="field in moreFiltersFields"
          :key="field.id"
          cols="2"
        >
          <b-text-view
            class="form-label"
            :for="field.id"
            :label="`${t(field.label)}:`"
          />
          <input
            :id="field.id"
            v-model.trim="filters[field.model]"
            class="form-control"
            :type="field.type || 'text'"
          />
        </b-col>
      </b-row>
    </template>
    <template #actions>
      <v-row>
        <b-button
          outline
          class="mr-2"
          @click="clearFilters"
        >
          {{ t("clear") }}
        </b-button>
        <b-button @click="fetchShipments">{{ t("search") }}</b-button>
      </v-row>
    </template>
  </TabCard>
</template>

<script setup>
import TabCard from "@/components/TabCard.vue"
import { computed, onMounted, onUnmounted } from "vue"
import { useI18n } from "vue-i18n"
import { searchShipmentStore } from "../../store/searchShipmentStore"
import { storeToRefs } from "pinia"

const { t } = useI18n()

const store = searchShipmentStore()
const { filters } = storeToRefs(store)
const { fetchShipments, clearFilters } = store

const tabs = computed(() => [
  {
    name: "simpleSearch",
    label: t("simpleSearch"),
    icon: "fa fa-home",
  },
  {
    name: "moreFilters",
    label: t("moreFilters"),
    icon: "fa fa-plus",
  },
])

const simpleSearchFields = computed(() => [
  {
    id: "refGotrans",
    label: "Ref. Go-Trans",
    model: "refGotrans",
  },
  {
    id: "refClient",
    label: `${t("refClient")}`,
    model: "refClient",
  },
])

const moreFiltersFields = [
  {
    id: "refGotrans",
    label: "Ref. Go-Trans",
    model: "refGotrans",
  },
  {
    id: "creationDate",
    label: "creationDate",
    model: "creationDate",
    type: "date",
  },
  {
    id: "refClient",
    label: `${t("refClient")}`,
    model: "refClient",
  },
  {
    id: "client",
    label: "client",
    model: "client",
  },
  {
    id: "status",
    label: "status",
    model: "status",
  },
  {
    id: "origin",
    label: "origin",
    model: "origin",
  },
  {
    id: "destination",
    label: "destination",
    model: "destination",
  },
  {
    id: "etd",
    label: "etd",
    model: "etd",
    type: "date",
  },
  {
    id: "eta",
    label: "eta",
    model: "eta",
    type: "date",
  },
]

const handleKeyPress = (event) => {
  if (event.key === "Enter") {
    fetchShipments()
  }
}

onMounted(() => {
  fetchShipments()
  window.addEventListener("keydown", handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeyPress)
})
</script>
