import apiClient from "@/plugins/axios"

const BASE_URL = "tracking"

export const getShipments = async (filters, pagination) => {
  const response = await apiClient.get(`${BASE_URL}/shipment-movements`, {
    params: {
      page: pagination.currentPage,
      limit: pagination.itemsPerPage,
      ...filters,
    },
  })

  return response
}

export const getShipment = async (refGotrans) => {
  const response = await apiClient.get(
    `${BASE_URL}/shipment-movements/${refGotrans}`
  )

  return response
}

export const getTimeline = async (shipmentID) => {
  const response = await apiClient.get(`${BASE_URL}/timeline/${shipmentID}`)
  return response
}

export const getFiles = async (shipmentID) => {
  const response = await apiClient.get(`${BASE_URL}/files/${shipmentID}`)
  return response
}

export const downloadFile = async (path, name) => {
  try {
    const response = await apiClient.post(
      `files/download`,
      { path },
      {
        responseType: "blob",
      }
    )

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", name)
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    return error
  }
}
