<template>
  <div class="col-sm-12">
    <div class="card tranaction-card">
      <div class="card-header card-no-border">
        <div class="header-top">
          <h5>Transactions</h5>
          <ul
            id="myTab"
            class="nav nav-tabs custom-tab"
            role="tablist"
          >
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                id="all-tab"
                class="nav-link active"
                data-bs-toggle="tab"
                data-bs-target="#all"
                type="button"
                role="tab"
                aria-controls="all"
                aria-selected="true"
              >
                All
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                id="buy-tab"
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#buy"
                type="button"
                role="tab"
                aria-controls="buy"
                aria-selected="false"
              >
                Buy
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
            >
              <button
                id="sell-tab"
                class="nav-link"
                data-bs-toggle="tab"
                data-bs-target="#sell"
                type="button"
                role="tab"
                aria-controls="sell"
                aria-selected="false"
              >
                Sell
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body pt-0">
        <div
          id="myTabContent"
          class="tab-content"
        >
          <div
            id="all"
            class="tab-pane fade show active"
            role="tabpanel"
            aria-labelledby="all-tab"
          >
            <AllTable />
          </div>
          <div
            id="buy"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="buy-tab"
          >
            <BuyTableVue />
          </div>
          <div
            id="sell"
            class="tab-pane fade"
            role="tabpanel"
            aria-labelledby="sell-tab"
          >
            <SellTableVue />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AllTable from "./TransactionsComponent/AllTable.vue"
import BuyTableVue from "./TransactionsComponent/BuyTable.vue"
import SellTableVue from "./TransactionsComponent/SellTable.vue"

export default {
  components: {
    AllTable,
    BuyTableVue,
    SellTableVue,
  },
}
</script>
