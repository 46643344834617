import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import apiClient from "@/plugins/axios";

export const decodeToken = (token) => {
	return token ? jwtDecode(token) : null;
};

export const setTokenHeaderAuthorization = (token) => {
	apiClient.interceptors.request.use(config => {
		config.headers.Authorization = `Bearer ${token}`;
		return config;
	}, error => Promise.reject(error));
};

export const setCookies = (accessToken, refreshToken) => {
	Cookies.set("accessToken", accessToken, {
		expires: 1,
		sameSite: "Strict",
		secure: true
	});
	Cookies.set("refreshToken", refreshToken, {
		expires: 7,
		sameSite: "Strict",
		secure: true
	});
};

export const clearAuthData = () => {
	Cookies.remove("accessToken");
	Cookies.remove("refreshToken");
};

export const getTokenExpirationDate = (token) => {
	const decodedToken = jwtDecode(token);
	const date = new Date(0);
	date.setUTCSeconds(decodedToken.exp);
	return date;
};

export const isTokenExpired = (token) => getTokenExpirationDate(token) < new Date();
