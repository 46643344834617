export const allDetails = [
  {
    cardClass: "card-body primary",
    dataInNumber: "2,908",
    iconClass: "icon-arrow-up",
    spanClass: "font-primary f-12 f-w-500",
    status: "+20%",
    svgIcon: "new-order",
    title: "New Orders",
  },
  {
    cardClass: "card-body warning",
    dataInNumber: "2,435",
    iconClass: "icon-arrow-up",
    spanClass: "font-warning f-12 f-w-500",
    status: "+50%",
    svgIcon: "customers",
    title: "New Customers",
  },
  {
    cardClass: "card-body secondary",
    dataInNumber: "$389k",
    iconClass: "icon-arrow-down",
    spanClass: "font-secondary f-12 f-w-500",
    status: "-10%",
    svgIcon: "sale",
    title: "Average Sale",
  },
  {
    cardClass: "card-body success",
    dataInNumber: "$3,908",
    iconClass: "icon-arrow-up",
    spanClass: "font-primary f-12 f-w-500",
    status: "+80%",
    svgIcon: "profit",
    title: "Gross Profit",
  },
]

export const valuableCustomer = [
  {
    email: "alma.lawson@gmail.com",
    image: require("@/assets/images/dashboard/user/1.jpg"),
    name: "Jane Cooper",
  },
  {
    email: "tim.jennings@gmail.com",
    image: require("@/assets/images/dashboard/user/2.jpg"),
    name: "Cameron Willia",
  },
  {
    email: "kenzi.lawson@gmail.com",
    image: require("@/assets/images/dashboard/user/6.jpg"),
    name: "Floyd Miles",
  },
  {
    email: "curtis.weaver@gmail.com",
    image: require("@/assets/images/dashboard/user/5.jpg"),
    name: "Dianne Russell",
  },
  {
    email: "curtis.weaver@gmail.com",
    image: require("@/assets/images/dashboard/user/3.jpg"),
    name: "Guy Hawkins",
  },
]

export const orderOverview = [
  {
    overviewInNumber: "10,098",
    svgIcon: "orders",
    title: "Orders",
  },
  {
    overviewInNumber: "$12,678",
    svgIcon: "expense",
    title: "Earning",
  },
  {
    overviewInNumber: "3,001",
    svgIcon: "doller-return",
    title: "Refunds",
  },
]

export const topcategories = [
  {
    image: require("@/assets/images/dashboard-2/category/1.png"),
    name: "Food & Drinks",
    orders: "12,200",
  },
  {
    image: require("@/assets/images/dashboard-2/category/2.png"),
    name: "Furniture",
    orders: "7,510",
  },
  {
    image: require("@/assets/images/dashboard-2/category/3.png"),
    name: "Grocery",
    orders: "15,475",
  },
  {
    image: require("@/assets/images/dashboard-2/category/4.png"),
    name: "Electronics",
    orders: "27,840",
  },
  {
    image: require("@/assets/images/dashboard-2/category/5.png"),
    name: "Toys & Games",
    orders: "8,788",
  },
  {
    image: require("@/assets/images/dashboard-2/category/6.png"),
    name: "Desktop",
    orders: "10,673",
  },
  {
    image: require("@/assets/images/dashboard-2/category/7.png"),
    name: "Mobile & Accessories",
    orders: "5,129",
  },
]

export const activities = [
  {
    class: "activity-dot-warning",
    date: "2nd Sep, Today",
    decription: "I don't like things to be staged or fussy.",
    title: "Anamika Comments this Poducts",
  },
  {
    class: "activity-dot-secondary",
    date: "3nd Sep, 2022",
    decription: "Fashion fades, only style remain the same.",
    title: "  Jacksion Purchase ",
  },
  {
    class: "activity-dot-success",
    date: "2nd Sep, Today",
    decription: "I like to be real. I don't like things to staged.",
    title: " Anamika Comments this Poducts",
  },
]

export const products = [
  {
    image: require("@/assets/images/dashboard-2/product/1.png"),
  },
  {
    image: require("@/assets/images/dashboard-2/product/2.png"),
  },
  {
    image: require("@/assets/images/dashboard-2/product/3.png"),
  },
]
