import axios from "axios"
import { toast } from "vue3-toastify"
import Cookies from "js-cookie"
import apiClient from "@/plugins/axios"
import { BASE_URL_KEYCLOAK } from "@/constants/api"
import { setCookies } from "@/utils/jwt"

const REALM = process.env.VUE_APP_KEYCLOAK_REALM

export const loginService = async (user) => {
  const response = await axios.post(
    `${BASE_URL_KEYCLOAK}/realms/${REALM}/protocol/openid-connect/token`,
    new URLSearchParams({
      client_id: "nexus-frontend-client",
      grant_type: "password",
      username: user?.username,
      password: user?.password,
    }),
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  )

  const { access_token, refresh_token } = response.data

  setCookies(access_token, refresh_token)

  return response
}

export const refreshTokenService = async () => {
  try {
    const refreshToken = Cookies.get("refreshToken");

    if (!refreshToken) {
      toast.error("No refresh token available.");
      return;
    }

    const response = await apiClient.post(
      `${BASE_URL_KEYCLOAK}/realms/${REALM}/protocol/openid-connect/token`,
      new URLSearchParams({
        client_id: "nexus-frontend-client",
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      }),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    const { access_token, refresh_token } = response.data

    setCookies(access_token, refresh_token)

    return response.data;
  } catch (error) {
    logoutService();
  }
}

export const logoutService = async () => {
  try {
    const refreshToken = Cookies.get("refreshToken")

    if (refreshToken) {
      await axios.post(
        `${BASE_URL_KEYCLOAK}/realms/${REALM}/protocol/openid-connect/logout`,
        new URLSearchParams({
          client_id: "nexus-frontend-client",
          refresh_token: refreshToken,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
    }

    Cookies.remove("accessToken")
    Cookies.remove("refreshToken")

    window.location.href = "/auth/login"
  } catch (error) {
    toast.error("Failed to logout. Please try again later.")
  }
}
