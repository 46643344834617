<template>
  <div>
    <div class="container-fluid">
      <div class="page-title">
        <div class="row">
          <div class="col-6 d-flex">
            <h3>{{ title }}</h3>
          </div>
          <div class="col-6">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a
                  class="btn btn-link p-0"
                  @click="goBack"
                >
                  <svg class="stroke-icon">
                    <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
                  </svg>
                </a>
              </li>
              <li
                v-if="main"
                class="breadcrumb-item"
              >
                {{ main }}
              </li>
              <li
                v-if="title"
                class="breadcrumb-item active"
              >
                {{ title }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "home",
    },
    routerName: {
      type: String,
      default: "home",
    },
    main: {
      type: String,
      default: "",
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: this.routerName })
    },
  },
}
</script>
