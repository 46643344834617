import moment from "moment"
var primary = localStorage.getItem("primary_color") || "#7366ff"
var secondary = localStorage.getItem("secondary_color") || "#f73164"
var series = {
  monthDataSeries1: {
    dates: [
      "13 Nov 2017",
      "14 Nov 2017",
      "15 Nov 2017",
      "16 Nov 2017",
      "17 Nov 2017",
      "20 Nov 2017",
      "21 Nov 2017",
    ],
    prices: [8107, 8300, 8260, 8400, 8350, 8500, 8350],
  },
}
function generateData(baseval, count, yrange) {
  var i = 0
  var series = []
  while (i < count) {
    var x = Math.floor(Math.random() * (750 - 1 + 1)) + 1
    var y =
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min
    var z = Math.floor(Math.random() * (75 - 15 + 1)) + 15

    series.push([x, y, z])
    baseval += 86400000
    i++
  }
  return series
}
export const cryptoDashboard = {
  options: {
    chart: {
      dropShadow: {
        blur: 10,
        color: "var(--theme-deafult)",
        enabled: true,
        left: 0,
        opacity: 0.35,
        top: 3,
      },
      height: 130,
      type: "radialBar",
    },
    colors: ["var(--theme-deafult)"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          showOn: "always",
          value: {
            color: "var(--body-font-color)",
            fontSize: "14px",
            offsetY: -10,
            show: true,
          },
        },
        hollow: {
          size: "60%",
        },
        track: {
          margin: 5,
          opacity: 1,
          strokeWidth: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            height: "70%",
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  options1: {
    chart: {
      dropShadow: {
        blur: 10,
        color: "var(--theme-secondary)",
        enabled: true,
        left: 0,
        opacity: 0.35,
        top: 3,
      },
      height: 130,
      type: "radialBar",
    },
    colors: ["#FFA941"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          showOn: "always",
          value: {
            color: "var(--body-font-color)",
            fontSize: "14px",
            offsetY: -10,
            show: true,
          },
        },
        hollow: {
          size: "60%",
        },
        track: {
          margin: 5,
          opacity: 1,
          strokeWidth: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            height: "70%",
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  options2: {
    chart: {
      dropShadow: {
        blur: 10,
        color: "#54BA4A",
        enabled: true,
        left: 0,
        opacity: 0.35,
        top: 3,
      },
      height: 130,
      type: "radialBar",
    },
    colors: ["#54BA4A"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          showOn: "always",
          value: {
            color: "var(--body-font-color)",
            fontSize: "14px",
            offsetY: -10,
            show: true,
          },
        },
        hollow: {
          size: "60%",
        },
        track: {
          margin: 5,
          opacity: 1,
          strokeWidth: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 2000,
        options: {
          chart: {
            height: "70%",
          },
        },
      },
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  options3: {
    chart: {
      dropShadow: {
        blur: 6,
        color: "#FFA941",
        enabled: true,
        enabledOnSeries: undefined,
        left: 0,
        opacity: 0.3,
        top: 6,
      },
      height: 120,
      offsetY: 10,
      toolbar: {
        show: false,
      },
      type: "line",
      width: 120,
    },
    colors: ["#FFA941"],
    grid: {
      show: false,
    },
    labels: [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
    ],
    legend: {
      show: false,
    },
    markers: {
      size: 0,
    },
    responsive: [
      {
        breakpoint: 1790,
        options: {
          chart: {
            height: 100,
            width: 100,
          },
        },
      },
      {
        breakpoint: 1661,
        options: {
          chart: {
            height: 100,
            width: "100%",
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    tooltip: {
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      y: {
        labels: {
          show: false,
        },
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  },
  options4: {
    chart: {
      dropShadow: {
        blur: 6,
        color: "var(--theme-deafult)",
        enabled: true,
        enabledOnSeries: undefined,
        left: 0,
        opacity: 0.3,
        top: 6,
      },
      height: 120,
      offsetY: 10,
      toolbar: {
        show: false,
      },
      type: "line",
      width: 120,
    },
    colors: ["var(--theme-deafult)"],
    grid: {
      show: false,
    },
    labels: ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep"],
    legend: {
      show: false,
    },
    markers: {
      size: 0,
    },
    responsive: [
      {
        breakpoint: 1790,
        options: {
          chart: {
            height: 100,
            width: 100,
          },
        },
      },
      {
        breakpoint: 1661,
        options: {
          chart: {
            height: 100,
            width: "100%",
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    tooltip: {
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      y: {
        labels: {
          show: false,
        },
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  },
  options5: {
    chart: {
      dropShadow: {
        blur: 6,
        color: "#54BA4A",
        enabled: true,
        enabledOnSeries: undefined,
        left: 0,
        opacity: 0.3,
        top: 6,
      },
      height: 120,
      offsetY: 10,
      toolbar: {
        show: false,
      },
      type: "line",
      width: 120,
    },
    colors: ["#54BA4A"],
    grid: {
      show: false,
    },
    labels: [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
    ],
    legend: {
      show: false,
    },
    markers: {
      size: 0,
    },
    responsive: [
      {
        breakpoint: 1790,
        options: {
          chart: {
            height: 100,
            width: 100,
          },
        },
      },
      {
        breakpoint: 1661,
        options: {
          chart: {
            height: 100,
            width: "100%",
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    tooltip: {
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      y: {
        labels: {
          show: false,
        },
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  },
  options6: {
    chart: {
      dropShadow: {
        blur: 15,
        color: "var(--theme-deafult)",
        enabled: true,
        enabledOnSeries: [1],
        left: 0,
        opacity: 0.3,
        top: 0,
      },
      height: 300,
      stacked: false,
      toolbar: {
        show: false,
      },
      type: "line",
    },
    colors: ["rgba(170, 175, 203, 0.2)", "var(--theme-deafult)"],
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
    },
    fill: {
      gradient: {
        colorStops: [
          {
            color: "#d867ac",
            offset: 0,
            opacity: 1,
          },
          {
            color: "#d867ac",
            offset: 30,
            opacity: 1,
          },
          {
            color: "var(--theme-deafult)",
            offset: 50,
            opacity: 1,
          },
          {
            color: "var(--theme-deafult)",
            offset: 80,
            opacity: 1,
          },
          {
            color: "var(--theme-deafult)",
            offset: 100,
            opacity: 1,
          },
        ],
        gradientToColors: ["var(--theme-deafult)", "#d867ac"],
        opacityFrom: 0.8,
        opacityTo: 0.8,
        shade: "light",
        shadeIntensity: 0.5,
        type: "vertical",
      },
      type: ["solid", "gradient"],
    },
    grid: {
      borderColor: "var(--chart-border)",
    },
    labels: [
      "Sep 10",
      "Sep 15",
      "Sep 20",
      "Sep 25",
      "Sep 30",
      "Oct 05",
      "Oct 10",
      "Oct 15",
      "Oct 20",
      "Oct 25",
    ],

    legend: {
      show: false,
    },
    markers: {
      size: 0,
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    stroke: {
      curve: "smooth",
      width: [0, 3],
    },
    tooltip: {
      intersect: false,
      shared: true,
    },
    xaxis: {
      labels: {
        style: {
          colors: "var(--chart-text-color)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "10px",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val + "k"
        },
        style: {
          colors: "var(--chart-text-color)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "12px",
        },
      },
      max: 20,
      min: 0,
      tickAmount: 5,
    },
  },
  options7: {
    chart: {
      height: 280,
      type: "radialBar",
    },
    colors: ["#54BA4A", "#FFA539", "#7366FF"],
    fill: {
      gradient: {
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        shade: "light",
        shadeIntensity: 0.25,
        stops: [50, 0, 80, 100],
        type: "horizontal",
      },
      type: "gradient",
    },
    labels: ["USD", "BTC", "ETH"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          show: false,
        },
        hollow: {
          image: require("@/assets/images/dashboard-4/portfolio-bg.png"),
          imageClipped: false,
          imageHeight: 230,
          imageWidth: 230,
          margin: 10,
          size: "40%",
        },
        track: {
          background: "var(--chart-progress-light)",
          opacity: 0.3,
        },
      },
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 260,
          },
          plotOptions: {
            radialBar: {
              hollow: {
                image: require("@/assets/images/dashboard-4/portfolio-bg.png"),
                imageClipped: false,
                imageHeight: 190,
                imageWidth: 190,
                margin: 10,
                size: "40%",
              },
            },
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            height: 320,
          },
          plotOptions: {
            radialBar: {
              hollow: {
                imageHeight: 260,
                imageWidth: 260,
              },
            },
          },
        },
      },
      {
        breakpoint: 650,
        options: {
          chart: {
            height: 280,
          },
          plotOptions: {
            radialBar: {
              hollow: {
                imageHeight: 220,
                imageWidth: 220,
              },
            },
          },
        },
      },
    ],
  },
  series: [78],
  series1: [80],
  series2: [48],
  series3: [
    {
      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
    },
  ],
  series4: [
    {
      data: [30, 25, 30, 25, 64, 40, 59, 52, 64],
    },
  ],
  series5: [
    {
      data: [30, 25, 36, 30, 64, 50, 45, 62, 60, 64],
    },
  ],
  series6: [
    {
      data: [4, 8, 4.5, 8, 13, 8.5, 12, 5, 7, 12],
      name: "TEAM A",
      type: "column",
    },
    {
      data: [2, 3, 2, 6, 8, 12, 9, 7, 9, 7],
      name: "TEAM C",
      type: "line",
    },
  ],
  series7: [44, 55, 67],
}
export const apexDashboard = {
  options: {
    chart: {
      height: 120,
      stacked: true,
      toolbar: {
        show: false,
      },
      type: "bar",
      width: 180,
    },
    colors: [primary, primary, "#F2F3F7", "#F2F3F7"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
        offsetX: -2,
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        barHeight: "80%",
        columnWidth: "40%",
        endingShape: "rounded",
        startingShape: "rounded",
        vertical: true,
      },
    },
    responsive: [
      {
        breakpoint: 1760,
        options: {
          chart: {
            width: 160,
          },
        },
      },
      {
        breakpoint: 1601,
        options: {
          chart: {
            height: 110,
          },
        },
      },
      {
        breakpoint: 1560,
        options: {
          chart: {
            width: 140,
          },
        },
      },
      {
        breakpoint: 1460,
        options: {
          chart: {
            width: 120,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 290,
          },
        },
      },
      {
        breakpoint: 1110,
        options: {
          chart: {
            width: 200,
          },
        },
      },
      {
        breakpoint: 700,
        options: {
          chart: {
            width: 150,
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 220,
          },
        },
      },
      {
        breakpoint: 420,
        options: {
          chart: {
            width: 150,
          },
        },
      },
    ],
    stroke: {
      width: 0,
    },
    tooltip: {
      shared: false,
      x: {
        show: false,
      },
      y: {
        show: false,
      },
      z: {
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        offsetX: 0,
        offsetY: 0,
        show: false,
      },
      axisTicks: {
        offsetX: 0,
        offsetY: 0,
        show: false,
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      labels: {
        offsetX: 0,
        offsetY: 0,
        show: false,
      },
      offsetX: 0,
      offsetY: 0,
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      max: 5,
      min: -5,
      show: false,
    },
  },
  options2: {
    chart: {
      dropShadow: {
        blur: 3,
        color: "#16C7F9",
        enabled: true,
        enabledOnSeries: undefined,
        left: 0,
        opacity: 0.3,
        top: 5,
      },
      height: 150,
      toolbar: {
        show: false,
      },
      type: "line",
      width: 200,
      zoom: {
        enabled: false,
      },
    },
    colors: ["#16C7F9"],
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 1780,
        options: {
          chart: {
            width: 180,
          },
        },
      },
      {
        breakpoint: 1680,
        options: {
          chart: {
            width: 160,
          },
        },
      },
      {
        breakpoint: 1601,
        options: {
          chart: {
            height: 110,
          },
        },
      },
      {
        breakpoint: 1560,
        options: {
          chart: {
            width: 140,
          },
        },
      },
      {
        breakpoint: 1460,
        options: {
          chart: {
            width: 120,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            width: 290,
          },
        },
      },
      {
        breakpoint: 1110,
        options: {
          chart: {
            width: 200,
          },
        },
      },
      {
        breakpoint: 700,
        options: {
          chart: {
            width: 150,
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          chart: {
            width: 220,
          },
        },
      },
      {
        breakpoint: 420,
        options: {
          chart: {
            width: 150,
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    tooltip: {
      marker: {
        show: false,
      },
      x: {
        show: false,
      },
      y: {
        show: false,
      },
      z: {
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  },
  options3: {
    chart: {
      dropShadow: {
        blur: 10,
        color: "#7064F5",
        enabled: true,
        left: 0,
        opacity: 0.1,
        top: 8,
      },
      height: 300,
      stacked: true,
      toolbar: {
        show: false,
      },
      type: "bar",
    },
    colors: [primary, "#AAAFCB"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "var(--chart-border)",
      show: true,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: "25px",
        horizontal: false,
      },
    },
    responsive: [
      {
        breakpoint: 1661,
        options: {
          chart: {
            height: 290,
          },
        },
      },
      {
        breakpoint: 767,
        options: {
          plotOptions: {
            bar: {
              columnWidth: "35px",
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
      {
        breakpoint: 481,
        options: {
          chart: {
            height: 200,
          },
        },
      },
      {
        breakpoint: 420,
        options: {
          chart: {
            height: 170,
          },
          plotOptions: {
            bar: {
              columnWidth: "40px",
            },
          },
        },
      },
    ],
    states: {
      hover: {
        filter: {
          type: "darken",
          value: 1,
        },
      },
    },
    stroke: {
      colors: "#fff",
      dashArray: 0,
      lineCap: "butt",
      width: 2,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
      ],
      labels: {
        style: {
          fontFamily: "Rubik, sans-serif",
        },
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style: {
          fontFamily: "Rubik, sans-serif",
        },
      },
      tickAmount: 3,
    },
  },
  options4: {
    chart: {
      height: 290,
      toolbar: {
        show: false,
      },
      type: "radialBar",
    },
    fill: {
      gradient: {
        colorStops: [
          {
            color: "#7366FF",
            offset: 0,
            opacity: 1,
          },
          {
            color: "#3EA4F1",
            offset: 20,
            opacity: 1,
          },
          {
            color: "#FFFFFF",
            offset: 100,
            opacity: 1,
          },
        ],
        opacityFrom: 1,
        opacityTo: 1,
        shade: "dark",
        shadeIntensity: 0.5,
        type: "horizontal",
      },
      type: "gradient",
    },
    labels: ["Total Profit"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            color: "#888",
            fontFamily: "Rubik, sans-serif",
            fontSize: "17px",
            fontWeight: "500",
            offsetY: 30,
            show: true,
          },
          show: true,
          value: {
            color: "#111",
            fontSize: "36px",
            formatter: function (val) {
              return parseInt(val)
            },
            offsetY: -8,
            show: true,
          },
        },
        hollow: {
          background: "var(--recent-chart-bg)",
          dropShadow: {
            blur: 4,
            enabled: true,
            left: 0,
            opacity: 0.05,
            top: 3,
          },
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          margin: 0,
          position: "front",
          size: "60%",
        },

        track: {
          background: "#F4F4F4",
          dropShadow: {
            blur: 10,
            color: "#ddd",
            enabled: true,
            left: 0,
            opacity: 1,
            top: 0,
          },
          margin: 0,
          strokeWidth: "67%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 1840,
        options: {
          chart: {
            height: 260,
          },
        },
      },
      {
        breakpoint: 1700,
        options: {
          chart: {
            height: 250,
          },
        },
      },
      {
        breakpoint: 1660,
        options: {
          chart: {
            dataLabels: {
              name: {
                fontSize: "15px",
              },
            },
            height: 230,
          },
        },
      },
      {
        breakpoint: 1561,
        options: {
          chart: {
            height: 275,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            height: 360,
          },
        },
      },
      {
        breakpoint: 1361,
        options: {
          chart: {
            height: 300,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 230,
          },
        },
      },
      {
        breakpoint: 1007,
        options: {
          chart: {
            height: 240,
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 230,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  options5: {
    chart: {
      height: 350,
      type: "bar",
    },
    dataLabels: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
  },
  options6: {
    chart: {
      dropShadow: {
        blur: 4,
        color: "#7366ff",
        enabled: true,
        enabledOnSeries: undefined,
        left: 0,
        opacity: 0.22,
        top: 5,
      },
      height: 135,
      toolbar: {
        show: false,
      },
      type: "line",
    },
    colors: ["#5527FF"],
    fill: {
      gradient: {
        colorStops: [
          {
            color: "#5527FF",
            offset: 0,
            opacity: 1,
          },
          {
            color: "#E069AE",
            offset: 100,
            opacity: 1,
          },
        ],
        gradientToColors: ["#5527FF"],
        opacityFrom: 1,
        opacityTo: 1,
        shade: "dark",
        shadeIntensity: 1,
        type: "horizontal",
      },
      type: "gradient",
    },
    grid: {
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    responsive: [
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 150,
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
        "Jan",
      ],
      labels: {
        style: {
          fontFamily: "Rubik, sans-serif",
        },
      },
      tickAmount: 10,
      tooltip: {
        enabled: false,
      },
      type: "category",
    },
    yaxis: {
      labels: {
        show: false,
      },
      max: 40,
      min: -10,
    },
  },
  series: [
    {
      data: [2.15, 3, 1.5, 2, 2.4, 3, 2.4, 2.8, 1.5, 1.7, 3, 2.5, 3, 2, 2.15, 3, 1.1,],
      name: "Daily",
    },
    {
      data: [
        -2.15, -3, -1.5, -2, -2.4, -3, -2.4, -2.8, -1.5, -1.7, -3, -2.5, -3, -2,
        -2.15, -3, -1.1,
      ],
      name: "Weekly",
    },
    {
      data: [
        -2.25, -2.35, -2.45, -2.55, -2.65, -2.75, -2.85, -2.95, -3.0, -3.1,
        -3.2, -3.25, -3.1, -3.0, -2.95, -2.85, -2.75,
      ],
      name: "Monthly",
    },
    {
      data: [
        2.25, 2.35, 2.45, 2.55, 2.65, 2.75, 2.85, 2.95, 3.0, 3.1, 3.2, 3.25,
        3.1, 3.0, 2.95, 2.85, 2.75,
      ],
      name: "Yearly",
    },
  ],
  series2: [
    {
      data: [210, 180, 650, 200, 600, 100, 800, 300, 500],
      name: "Desktops",
    },
  ],
  series3: [
    {
      data: [
        200, 200, 350, 400, 200, 250, 250, 350, 350, 500, 500, 700, 850, 700,
        400, 400, 250, 250, 400, 350, 400,
      ],
      name: "Earning",
    },
    {
      data: [
        400, 600, 700, 400, 700, 800, 800, 850, 850, 900, 900, 700, 600, 500,
        800, 800, 800, 800, 400, 700, 800,
      ],
      name: "Expense",
    },
  ],
  series4: [100],
  series5: [
    {
      data: [
        {
          x: "Team A",
          y: [1, 5],
        },
        {
          x: "Team B",
          y: [4, 6],
        },
        {
          x: "Team C",
          y: [5, 8],
        },
        {
          x: "Team D",
          y: [3, 11],
        },
      ],
    },
    {
      data: [
        {
          x: "Team A",
          y: [2, 6],
        },
        {
          x: "Team B",
          y: [1, 3],
        },
        {
          x: "Team C",
          y: [7, 8],
        },
        {
          x: "Team D",
          y: [5, 9],
        },
      ],
    },
  ],
  series6: [
    {
      data: [10, 5, 15, 0, 15, 12, 29, 29, 29, 12, 15, 5],
      name: "Growth",
    },
  ],
}
export const ecomDashboard = {
  options: {
    chart: {
      height: 235,
      toolbar: {
        show: false,
      },
      type: "line",
    },
    colors: [
      "#7064F5",
      "#7064F5",
      "#7064F5",
      "#7064F5",
      "#7064F5",
      "#7064F5",
      "#7064F5",
      "#7064F5",
      "#7064F5",
      "#7064F5",
      "#7064F5",
      "#7064F5",
    ],
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        colorStops: [
          {
            color: "#e183b7",
            offset: 0,
            opacity: 1,
          },
          {
            color: "#ff8ba7",
            offset: 20,
            opacity: 1,
          },
          {
            color: "#b377d0",
            offset: 30,
            opacity: 1,
          },
          {
            color: "#7064f5",
            offset: 65,
            opacity: 1,
          },
          {
            color: "#b778cf",
            offset: 70,
            opacity: 1,
          },
          {
            color: "#eb86b2",
            offset: 80,
            opacity: 1,
          },
          {
            color: "#a873d7",
            offset: 100,
            opacity: 1,
          },
        ],
        gradientToColors: [
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
          "#FF8BA7",
        ],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        shade: "dark",
        shadeIntensity: 1,
        type: "horizontal",
      },
      type: "gradient",
    },
    grid: {
      borderColor: "#e7e7e7",
      column: {
        colors: ["transparent", "var(--light-background)"],
        opacity: 0.5,
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 1400,
        options: {
          chart: {
            height: 230,
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    tooltip: {
      shared: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "01",
        "03",
        "05",
        "07",
        "09",
        "10",
        "11",
        "12",
        "15",
        "16",
        "18",
      ],
      tickAmount: 6,
      tickPlacement: "between",
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        color: "var(--chart-border)",
        offsetX: 12,
        offsetY: 5,
        show: true,
      },
      max: 40,
      min: 5,
    },
  },
  options1: {
    chart: {
      height: 300,
      type: "donut",
    },

    colors: ["#54BA4A", "var(--theme-deafult)", "#FFA941"],
    dataLabels: {
      enabled: false,
    },
    labels: ["Shoes", "Grocery", "other"],
    legend: {
      fontFamily: "Rubik, sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      itemMargin: {
        horizontal: 7,
        vertical: 0,
      },
      labels: {
        colors: ["var(--chart-text-color)"],
      },
      markers: {
        height: 6,
        width: 6,
      },
      position: "bottom",
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            name: {
              offsetY: 4,
            },
            show: true,
            total: {
              fontFamily: "Rubik, sans-serif",
              fontSize: "20px",
              fontWeight: 500,
              formatter: () => "Total Profit",
              label: "$ 34,098",
              show: true,
            },
          },
          size: "83%",
        },
        expandOnClick: false,
      },
    },
    responsive: [
      {
        breakpoint: 1630,
        options: {
          chart: {
            height: 360,
          },
        },
      },
      {
        breakpoint: 1584,
        options: {
          chart: {
            height: 400,
          },
        },
      },
      {
        breakpoint: 1473,
        options: {
          chart: {
            height: 250,
          },
        },
      },
      {
        breakpoint: 1425,
        options: {
          chart: {
            height: 270,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            height: 320,
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
    states: {
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      normal: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      colors: ["var(--light2)"],
      width: 10,
    },
  },
  options2: {
    chart: {
      dropShadow: {
        blur: 4,
        color: "#000",
        enabled: true,
        left: 0,
        opacity: 0.08,
        top: 2,
      },
      height: 300,
      stacked: false,
      toolbar: {
        show: false,
      },
      type: "line",
    },
    colors: ["#7064F5", "#54BA4A", "#FF3364"],
    fill: {
      gradient: {
        opacityFrom: 0.4,
        opacityTo: 0,
        shade: "light",
        stops: [0, 100],
        type: "vertical",
      },
      type: "gradient",
    },
    grid: {
      borderColor: "var(--chart-border)",
      padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
      position: "back",
      show: true,
      strokeDashArray: 0,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
    ],
    legend: {
      show: false,
    },
    markers: {
      discrete: [
        {
          dataPointIndex: 2,
          fillColor: "#7064F5",
          seriesIndex: 0,
          size: 5,
          sizeOffset: 3,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 2,
          fillColor: "#54BA4A",
          seriesIndex: 1,
          size: 5,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 2,
          fillColor: "#FF3364",
          seriesIndex: 2,
          size: 5,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 5,
          fillColor: "#7064F5",
          seriesIndex: 0,
          size: 5,
          sizeOffset: 3,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 5,
          fillColor: "#54BA4A",
          seriesIndex: 1,
          size: 5,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 5,
          fillColor: "#FF3364",
          seriesIndex: 2,
          size: 5,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 9,
          fillColor: "#7064F5",
          seriesIndex: 0,
          size: 5,
          sizeOffset: 3,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 9,
          fillColor: "#54BA4A",
          seriesIndex: 1,
          size: 5,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 9,
          fillColor: "#FF3364",
          seriesIndex: 2,
          size: 5,
          strokeColor: "var(--white)",
        },
      ],
      hover: {
        size: 5,
        sizeOffset: 0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 250,
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: [2, 2, 2],
    },
    tooltip: {
      intersect: false,
      shared: false,
    },
    xaxis: {
      axisBorder: {
        color: "var(--chart-border)",
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 4,
      tickPlacement: "between",
      tooltip: {
        enabled: false,
      },
      type: "category",
    },
    yaxis: {
      min: 0,
      tickAmount: 6,
      tickPlacement: "between",
    },
  },
  options3: {
    chart: {
      height: 180,
      toolbar: {
        show: false,
      },
      type: "bar",
    },
    colors: ["var(--light-bg)"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 0.7,
    },
    grid: {
      show: false,
    },
    plotOptions: {
      bar: {
        columnWidth: "55%",
        horizontal: false,
      },
    },
    responsive: [
      {
        breakpoint: 405,
        options: {
          chart: {
            height: 150,
          },
        },
      },
    ],
    states: {
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      normal: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 2,
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
      labels: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  },
  options4: {
    chart: {
      height: 270,
      toolbar: {
        show: false,
      },
      type: "bar",
    },
    colors: ["#FFA941", "var(--theme-deafult)"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "var(--chart-border)",
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      fontFamily: "Rubik, sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      horizontalAlign: "left",
      itemMargin: {
        horizontal: 10,
      },
      labels: {
        colors: "var(--chart-text-color)",
      },
      markers: {
        height: 6,
        radius: 12,
        width: 6,
      },
      position: "top",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
        horizontal: false,
      },
    },
    responsive: [
      {
        breakpoint: 1366,
        options: {
          grid: {
            padding: {
              right: 0,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "80%",
            },
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          plotOptions: {
            bar: {
              columnWidth: "70%",
            },
          },
        },
      },
      {
        breakpoint: 576,
        options: {
          grid: {
            padding: {
              right: 5,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "60%",
            },
          },
        },
      },
    ],
    stroke: {
      colors: ["transparent"],
      show: true,
      width: 6,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      labels: {
        style: {
          fontFamily: "Rubik, sans-serif",
        },
      },
      tickAmount: 4,
      tickPlacement: "between",
    },
    yaxis: {
      labels: {
        style: {
          fontFamily: "Rubik, sans-serif",
        },
      },
      max: 100,
      min: 0,
      tickAmount: 5,
      tickPlacement: "between",
    },
  },
  series: [
    {
      data: [23, 23, 20, 20, 32, 32, 40, 32, 32, 25, 30, 30],
      name: "Sales",
    },
    {
      data: [22, 22, 19, 19, 31, 31, 39, 31, 31, 24, 29, 29],
      name: "sales",
    },
    {
      data: [21, 21, 18, 18, 30, 30, 38, 30, 30, 23, 28, 28],
      name: "sales",
    },
    {
      data: [20, 20, 17, 17, 29, 29, 37, 29, 29, 22, 27, 27],
      name: "sales",
    },
    {
      data: [19, 19, 16, 16, 28, 28, 36, 28, 28, 21, 26, 26],
      name: "sales",
    },
    {
      data: [18, 18, 15, 15, 27, 27, 35, 27, 27, 20, 25, 25],
      name: "sales",
    },
    {
      data: [17, 17, 14, 14, 26, 26, 34, 26, 26, 19, 24, 24],
      name: "sales",
    },
    {
      data: [16, 16, 13, 13, 25, 25, 33, 25, 25, 18, 23, 23],
      name: "sales",
    },
    {
      data: [16, 16, 13, 13, 25, 25, 33, 25, 25, 18, 23, 23],
      name: "sales",
    },
    {
      data: [15, 15, 12, 12, 24, 24, 32, 24, 24, 17, 22, 22],
      name: "sales",
    },
    {
      data: [14, 14, 11, 11, 23, 23, 31, 23, 23, 16, 21, 21],
      name: "sales",
    },
    {
      data: [13, 13, 10, 10, 22, 22, 30, 22, 22, 15, 20, 20],
      name: "sales",
    },
  ],
  series1: [30, 55, 35],
  series2: [
    {
      data: [44, 55, 35, 50, 67, 50, 55, 45, 32, 38, 45],
      name: "Earning",
      type: "area",
    },
    {
      data: [35, 30, 23, 40, 50, 35, 40, 52, 67, 50, 55],
      name: "Order",
      type: "area",
    },
    {
      data: [25, 20, 15, 25, 32, 20, 30, 35, 23, 30, 20],
      name: "Refunds",
      type: "area",
    },
  ],
  series3: [
    {
      data: [
        30, 40, 18, 25, 18, 10, 20, 35, 22, 40, 30, 38, 20, 35, 11, 28, 40, 11,
        28, 40, 11, 28, 40, 11, 28, 40, 11,
      ],
      name: "Revenue",
    },
  ],
  series4: [
    {
      data: [18, 10, 65, 18, 28, 10],
      name: "Active",
    },
    {
      data: [25, 50, 30, 30, 25, 45],
      name: "Bounce",
    },
  ],
}
export const onlineDashboard = {
  options: {
    chart: {
      height: 360,
      offsetX: -28,
      type: "radialBar",
      width: 240,
    },
    colors: ["var(--theme-deafult)", "#FFA941"],
    labels: ["Progress", "Done"],
    legend: {
      fontFamily: "Rubik, sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      horizontalAlign: "center",
      labels: {
        colors: "var(--chart-text-color)",
      },
      markers: {
        height: 6,
        width: 6,
      },
      offsetY: -15,
      position: "bottom",
      show: true,
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            color: "var(--chart-text-color)",
            fontFamily: "Rubik, sans-serif",
            fontWeight: 500,
            offsetY: 20,
          },
          total: {
            fontSize: "12px",
            formatter: function () {
              return "89%"
            },
            label: "Task Done!",
            show: true,
          },
          value: {
            fontFamily: "Rubik, sans-serif",
            fontSize: "22px",
            fontWeight: 500,
            offsetY: -16,
          },
        },
        hollow: {
          image: require("@/assets/images/dashboard-3/round.png"),
          imageClipped: false,
          imageHeight: 115,
          imageWidth: 115,
          margin: 5,
          size: "70%",
        },
        track: {
          background: "transparent",
        },
      },
    },
    responsive: [
      {
        breakpoint: 1830,
        options: {
          chart: {
            offsetX: -40,
          },
        },
      },
      {
        breakpoint: 1750,
        options: {
          chart: {
            offsetX: -50,
          },
        },
      },
      {
        breakpoint: 1661,
        options: {
          chart: {
            offsetX: -10,
          },
        },
      },
      {
        breakpoint: 1530,
        options: {
          chart: {
            offsetX: -25,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          chart: {
            offsetX: 10,
          },
        },
      },
      {
        breakpoint: 1300,
        options: {
          chart: {
            offsetX: -10,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: 255,
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            width: 245,
          },
        },
      },
      {
        breakpoint: 600,
        options: {
          chart: {
            width: 225,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  options1: {
    chart: {
      height: 315,
      toolbar: {
        show: false,
      },
      type: "line",
    },
    colors: [
      "var(--theme-deafult)",
      "#F47DEA",
      "#FFA941",
      "#FFC200",
      "#54BA4A",
      "#3DA831",
      "#57B9F6",
      "#FF3377",
      "#773ACE",
      "#945CFF",
      "#7366ff",
    ],
    fill: {
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
        shade: "light",
        shadeIntensity: 0.5,
        stops: [0, 80, 100],
        type: "vertical",
      },
      type: [
        "solid",
        "solid",
        "solid",
        "solid",
        "solid",
        "solid",
        "solid",
        "solid",
        "solid",
        "solid",
        "gradient",
      ],
    },
    grid: {
      borderColor: "var(--chart-border)",
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    markers: {
      discrete: [
        {
          dataPointIndex: 0,
          fillColor: "#7064F5",
          seriesIndex: 0,
          size: 6,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 5,
          fillColor: "#7064F5",
          seriesIndex: 1,
          size: 6,
          strokeColor: "var(--white)",
        },
        {
          dataPointIndex: 3,
          fillColor: "#7064F5",
          seriesIndex: 2,
          size: 6,
          strokeColor: "var(--white)",
        },
      ],
    },
    responsive: [
      {
        breakpoint: 1661,
        options: {
          chart: {
            height: 265,
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 1.5,
    },
    tooltip: {
      intersect: false,
      shared: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "Sep 5",
        "Sep 8",
        "Sep 12",
        "Sep 16",
        "Sep 18",
        "Sep 17",
        "Sep 23",
        "Sep 26",
      ],
      labels: {
        style: {
          colors: "var(--chart-text-color)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      tickAmount: 12,
      tooltip: {
        enabled: false,
      },
      type: "category",
    },
    yaxis: {
      labels: {
        style: {
          colors: "var(--chart-text-color)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      max: 50,
      min: 0,
      tickAmount: 5,
    },
  },
  options2: {
    chart: {
      dropShadow: {
        blur: 5,
        color: "#7064F5",
        enabled: true,
        left: 0,
        opacity: 0.35,
        top: 10,
      },
      height: 300,
      toolbar: {
        show: false,
      },
      type: "bar",
    },
    colors: ["#7064F5", "#8D83FF"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        gradientToColors: ["#7064F5", "#8D83FF"],
        opacityFrom: 0.98,
        opacityTo: 0.85,
        shade: "light",
        stops: [0, 100],
        type: "vertical",
      },
      type: "gradient",
    },
    grid: {
      borderColor: "var(--chart-dashed-border)",
      strokeDashArray: 5,
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        columnWidth: "30%",
      },
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: ["S", "M", "T", "W", "T", "F", "S"],
      labels: {
        style: {
          colors: "var(--chart-text-color)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "12px",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter: function (val) {
          return val + " " + "Hr"
        },
        style: {
          colors: "var(--chart-text-color)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "12px",
        },
      },
    },
  },
  options3: {
    chart: {
      height: 305,
      toolbar: {
        show: false,
      },
      type: "rangeBar",
    },
    dataLabels: {
      background: {
        borderRadius: 12,
        borderWidth: 1,
        enabled: true,
        foreColor: "var(--chart-text-color)",
        opacity: 0.9,
        padding: 10,
      },
      enabled: true,
      formatter: function (val, opts) {
        var label = opts.w.globals.labels[opts.dataPointIndex]
        return label
      },
      offsetX: 0,
      offsetY: 0,
      textAnchor: "middle",
    },
    grid: {
      row: {
        colors: ["var(--light-background)", "var(--white)"],
        opacity: 1,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "50%",
        dataLabels: {
          hideOverflowingLabels: false,
        },
        distributed: true,
        horizontal: true,
      },
    },
    responsive: [
      {
        breakpoint: 1661,
        options: {
          chart: {
            height: 295,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 370,
          },
        },
      },
      {
        breakpoint: 575,
        options: {
          chart: {
            height: 300,
          },
        },
      },
    ],
    states: {
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      normal: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      width: 2,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      position: "top",
      type: "datetime",
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      show: false,
    },
  },
  options4: {
    chart: {
      height: 80,
      type: "donut",
    },
    colors: [
      "var(--theme-deafult)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
    ],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              fontFamily: "Rubik, sans-serif",
              fontSize: "11px",
              label: "Total",
              show: true,
              showAlways: false,
            },
            value: {
              color: "var(--chart-text-color)",
              fontFamily: "Rubik, sans-serif",
              fontSize: "11px",
              fontWeight: 400,
              formatter: function (val) {
                return val
              },
              offsetY: -12,
            },
          },
        },
      },
    },
    states: {
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      normal: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      colors: "var(--white)",
      width: 1,
    },
    tooltip: {
      enabled: false,
    },
  },
  options5: {
    chart: {
      height: 80,
      type: "donut",
    },
    colors: [
      "var(--theme-deafult)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
    ],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              fontFamily: "Rubik, sans-serif",
              fontSize: "11px",
              label: "Total",
              show: true,
              showAlways: false,
            },
            value: {
              color: "var(--chart-text-color)",
              fontFamily: "Rubik, sans-serif",
              fontSize: "11px",
              fontWeight: 400,
              formatter: function (val) {
                return val
              },
              offsetY: -12,
            },
          },
        },
      },
    },
    states: {
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      normal: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      colors: "var(--white)",
      width: 1,
    },
    tooltip: {
      enabled: false,
    },
  },
  options6: {
    chart: {
      height: 80,
      type: "donut",
    },
    colors: [
      "var(--theme-deafult)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
      "var(--chart-progress-light)",
    ],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              fontFamily: "Rubik, sans-serif",
              fontSize: "11px",
              label: "Total",
              show: true,
              showAlways: false,
            },
            value: {
              color: "var(--chart-text-color)",
              fontFamily: "Rubik, sans-serif",
              fontSize: "11px",
              fontWeight: 400,
              formatter: function (val) {
                return val
              },
              offsetY: -12,
            },
          },
        },
      },
    },
    states: {
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
        },
      },
      hover: {
        filter: {
          type: "none",
        },
      },
      normal: {
        filter: {
          type: "none",
        },
      },
    },
    stroke: {
      colors: "var(--white)",
      width: 1,
    },
    tooltip: {
      enabled: false,
    },
  },
  series: [38, 60],
  series1: [
    {
      data: [25, 30, 43, 25, 38, 25, 33, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 30, 41, 25, 36, 25, 31, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 29, 37, 25, 34, 25, 29, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 28, 34, 25, 32, 25, 28, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 27, 30, 25, 28, 25, 27, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 26, 24, 25, 24, 25, 24, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 26, 20, 25, 21, 25, 23, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 24, 16, 25, 18, 25, 22, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 23, 12, 25, 15, 25, 21, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 23, 10, 25, 13, 25, 19, 25],
      name: "growth",
      type: "line",
    },
    {
      data: [25, 28, 37, 25, 33, 25, 27, 25],
      name: "growth",
      type: "area",
    },
  ],
  series2: [
    {
      data: [2, 4, 2.5, 1.5, 5.5, 1.5, 4],
      name: "Activity",
    },
  ],
  series3: [
    {
      data: [
        {
          fillColor: "var(--white)",
          strokeColor: "var(--theme-deafult)",
          x: "Team Meetup",
          y: [
            new Date("2022-01-20").getTime(),
            new Date("2022-03-8").getTime(),
          ],
        },
        {
          fillColor: "var(--white)",
          strokeColor: "#54BA4A",
          x: "Theme Development",
          y: [
            new Date("2022-01-8").getTime(),
            new Date("2022-02-30").getTime(),
          ],
        },
        {
          fillColor: "var(--white)",
          strokeColor: "#FFAA05",
          x: "UI/UX Design",
          y: [
            new Date("2022-02-01").getTime(),
            new Date("2022-03-10").getTime(),
          ],
        },
        {
          fillColor: "var(--white)",
          strokeColor: "#FF3364",
          x: "Logo Creater",
          y: [
            new Date("2022-02-10").getTime(),
            new Date("2022-03-15").getTime(),
          ],
        },
      ],
    },
  ],
  series4: [20, 5, 5, 5, 5, 5, 5],
  series5: [50, 10, 10, 10],
  series6: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
}

export const socialDashboard = {
  options: {
    chart: {
      dropShadow: {
        blur: 10,
        color: "var(--theme-deafult)",
        enabled: true,
        left: 0,
        opacity: 0.35,
        top: 3,
      },
      height: 130,
      type: "radialBar",
    },
    colors: ["var(--theme-deafult)"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          showOn: "always",
          value: {
            color: "var(--body-font-color)",
            fontSize: "14px",
            offsetY: -10,
            show: true,
          },
        },
        hollow: {
          size: "60%",
        },
        track: {
          margin: 5,
          opacity: 1,
          strokeWidth: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  options1: {
    chart: {
      dropShadow: {
        blur: 10,
        color: "#FFA941",
        enabled: true,
        left: 0,
        opacity: 0.35,
        top: 3,
      },
      height: 130,
      type: "radialBar",
    },
    colors: ["#FFA941"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          showOn: "always",
          value: {
            color: "var(--body-font-color)",
            fontSize: "14px",
            offsetY: -10,
            show: true,
          },
        },
        hollow: {
          size: "60%",
        },
        track: {
          margin: 5,
          opacity: 1,
          strokeWidth: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  options2: {
    chart: {
      dropShadow: {
        blur: 10,
        color: "#57B9F6",
        enabled: true,
        left: 0,
        opacity: 0.35,
        top: 3,
      },
      height: 130,
      type: "radialBar",
    },
    colors: ["#57B9F6"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          showOn: "always",
          value: {
            color: "var(--body-font-color)",
            fontSize: "14px",
            offsetY: -10,
            show: true,
          },
        },
        hollow: {
          size: "60%",
        },
        track: {
          margin: 5,
          opacity: 1,
          strokeWidth: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  options3: {
    chart: {
      dropShadow: {
        blur: 2,
        color: ["#FFA941", "#7366FF"],
        enabled: true,
        left: 0,
        opacity: 0.1,
        top: 8,
      },
      height: 265,
      toolbar: {
        show: false,
      },
      type: "line",
    },
    colors: ["#FFA941", "#7366FF"],
    fill: {
      gradient: {
        opacityFrom: 0.95,
        opacityTo: 1,
        shade: "light",
        shadeIntensity: 1,
        stops: [0, 90, 100],
        type: "horizontal",
      },
      opacity: 1,
      type: "gradient",
    },
    grid: {
      borderColor: "var(--chart-border)",
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: false,
    },
    markers: {
      discrete: [
        {
          dataPointIndex: 4,
          fillColor: "#7064F5",
          seriesIndex: 1,
          size: 6,
          strokeColor: "var(--white)",
        },
      ],
    },
    responsive: [
      {
        breakpoint: 1694,
        options: {
          chart: {
            height: 240,
          },
        },
      },
      {
        breakpoint: 1661,
        options: {
          chart: {
            height: 265,
          },
        },
      },
      {
        breakpoint: 1412,
        options: {
          chart: {
            height: 240,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 260,
          },
        },
      },
      {
        breakpoint: 1040,
        options: {
          chart: {
            height: 240,
          },
        },
      },
      {
        breakpoint: 992,
        options: {
          chart: {
            height: 255,
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      opacity: 1,
      width: 2,
    },
    tooltip: {
      intersect: false,
      marker: {
        height: 5,
        width: 5,
      },
      shared: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        "Sep 5",
        "Sep 8",
        "Sep 12",
        "Sep 16",
        "Sep 18",
        "Sep 17",
        "Sep 23",
        "Sep 26",
        "Sep 30",
      ],
      crosshairs: {
        show: false,
      },
      labels: {
        style: {
          colors: "var(--chart-text-color)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      tickAmount: 12,
      tooltip: {
        enabled: false,
      },
      type: "category",
    },
    yaxis: {
      labels: {
        style: {
          colors: "var(--chart-text-color)",
          fontFamily: "Rubik, sans-serif",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      max: 60,
      min: 10,
      tickAmount: 5,
    },
  },
  options4: {
    annotations: {
      points: [
        {
          marker: {
            fillColor: "#54BA4A",
            radius: 2,
            size: 4,
            strokeColor: "var(--white)",
          },
          x: 90,
          y: 58,
        },
      ],
    },
    chart: {
      dropShadow: {
        blur: 3,
        color: "#54BA4A",
        enabled: true,
        left: 0,
        opacity: 0.2,
        top: 8,
      },
      height: 150,
      toolbar: {
        show: false,
      },
      type: "line",
      width: 125,
      zoom: {
        enabled: false,
      },
    },
    colors: ["#54BA4A"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        colorStops: [
          {
            color: "#54BA4A",
            offset: 0,
            opacity: 0.1,
          },
          {
            color: "#54BA4A",
            offset: 30,
            opacity: 0.8,
          },
          {
            color: "#54BA4A",
            offset: 80,
            opacity: 1,
          },
          {
            color: "#54BA4A",
            offset: 100,
            opacity: 0.1,
          },
        ],
        opacityFrom: 0.95,
        opacityTo: 1,
        shade: "light",
        shadeIntensity: 1,
        type: "vertical",
      },
      opacity: 1,
      type: "gradient",
    },
    grid: {
      show: false,
    },
    markers: {
      hover: {
        size: 3,
      },
    },
    responsive: [
      {
        breakpoint: 1694,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "#54BA4A",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 75,
                y: 58,
              },
            ],
          },
          chart: {
            width: 100,
          },
        },
      },
      {
        breakpoint: 1661,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "#54BA4A",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 90,
                y: 58,
              },
            ],
          },
          chart: {
            width: 120,
          },
        },
      },
      {
        breakpoint: 1378,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "#54BA4A",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 75,
                y: 58,
              },
            ],
          },
          chart: {
            width: 100,
          },
        },
      },
      {
        breakpoint: 1288,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "#54BA4A",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 50,
                y: 58,
              },
            ],
          },
          chart: {
            width: 80,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "#54BA4A",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 85,
                y: 58,
              },
            ],
          },
          chart: {
            width: 110,
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    tooltip: {
      x: {
        show: false,
      },
      z: {
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  },
  options5: {
    annotations: {
      points: [
        {
          marker: {
            fillColor: "var(--theme-secondary)",
            radius: 2,
            size: 4,
            strokeColor: "var(--white)",
          },
          x: 90,
          y: 58,
        },
      ],
    },
    chart: {
      dropShadow: {
        blur: 3,
        color: "var(--theme-secondary)",
        enabled: true,
        left: 0,
        opacity: 0.2,
        top: 8,
      },
      height: 150,
      toolbar: {
        show: false,
      },
      type: "line",
      width: 125,
      zoom: {
        enabled: false,
      },
    },
    colors: ["var(--theme-secondary)"],
    dataLabels: {
      enabled: false,
    },
    fill: {
      gradient: {
        colorStops: [
          {
            color: "var(--theme-secondary)",
            offset: 0,
            opacity: 0.1,
          },
          {
            color: "var(--theme-secondary)",
            offset: 30,
            opacity: 0.8,
          },
          {
            color: "var(--theme-secondary)",
            offset: 80,
            opacity: 1,
          },
          {
            color: "var(--theme-secondary)",
            offset: 100,
            opacity: 0.1,
          },
        ],
        opacityFrom: 0.95,
        opacityTo: 1,
        shade: "light",
        shadeIntensity: 1,
        type: "vertical",
      },
      opacity: 1,
      type: "gradient",
    },
    grid: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 1694,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "var(--theme-secondary)",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 75,
                y: 58,
              },
            ],
          },
          chart: {
            width: 100,
          },
        },
      },
      {
        breakpoint: 1661,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "var(--theme-secondary)",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 90,
                y: 58,
              },
            ],
          },
          chart: {
            width: 120,
          },
        },
      },
      {
        breakpoint: 1378,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "var(--theme-secondary)",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 75,
                y: 58,
              },
            ],
          },
          chart: {
            width: 100,
          },
        },
      },
      {
        breakpoint: 1288,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "var(--theme-secondary)",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 50,
                y: 58,
              },
            ],
          },
          chart: {
            width: 80,
          },
        },
      },
      {
        breakpoint: 1200,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  fillColor: "var(--theme-secondary)",
                  radius: 2,
                  size: 4,
                  strokeColor: "var(--white)",
                },
                x: 85,
                y: 58,
              },
            ],
          },
          chart: {
            width: 110,
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    tooltip: {
      x: {
        show: false,
      },
      z: {
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  },
  options6: {
    chart: {
      dropShadow: {
        blur: 8,
        color: "var(--theme-deafult)",
        enabled: true,
        left: 8,
        opacity: 0.2,
        top: 5,
      },
      height: 325,
      type: "radialBar",
      width: 325,
    },
    colors: ["var(--theme-deafult)", "#FFA941"],
    labels: ["Male", "Female"],
    legend: {
      fontFamily: "Rubik, sans-serif",
      fontSize: "14px",
      fontWeight: 500,
      horizontalAlign: "center",
      labels: {
        colors: "var(--chart-text-color)",
      },
      markers: {
        height: 6,
        width: 6,
      },
      offsetY: -15,
      position: "right",
      show: true,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          image: require("@/assets/images/dashboard-5/follower.png"),
          imageClipped: false,
          imageHeight: 50,
          imageWidth: 50,
          margin: 5,
          size: "70%",
        },
        track: {
          background: "transparent",
        },
      },
    },
    responsive: [
      {
        breakpoint: 718,
        options: {
          chart: {
            height: 230,
            width: "100%",
          },
          legend: {
            show: false,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  options7: {
    annotations: {
      points: [
        {
          marker: {
            cssClass: "apexcharts-custom-class",
            fillColor: "#7366FF",
            radius: 2,
            size: 15,
            strokeColor: "var(--view-border-marker)",
            strokeWidth: 20,
          },
          x: "Feb",
          y: 44,
        },
      ],
    },
    chart: {
      dropShadow: {
        blur: 3,
        color: "var(--theme-deafult)",
        enabled: true,
        left: 0,
        opacity: 0.2,
        top: 8,
      },
      height: 305,
      toolbar: {
        show: false,
      },
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["var(--theme-deafult)"],
    dataLabels: {
      enabled: false,
    },

    fill: {
      gradient: {
        colorStops: [
          {
            color: "var(--theme-deafult)",
            offset: 0,
            opacity: 0.05,
          },
          {
            color: "var(--theme-deafult)",
            offset: 30,
            opacity: 1,
          },
          {
            color: "var(--theme-deafult)",
            offset: 80,
            opacity: 1,
          },
          {
            color: "var(--theme-deafult)",
            offset: 100,
            opacity: 0.1,
          },
        ],
        opacityFrom: 0.95,
        opacityTo: 1,
        shade: "light",
        shadeIntensity: 1,
        type: "vertical",
      },
      opacity: 1,
      type: "gradient",
    },
    grid: {
      column: {
        colors: ["var(--view-grid-bg)", "transparent"],
      },
      row: {
        colors: ["var(--view-grid-bg)", "transparent"],
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 200,
          },
        },
      },
      {
        breakpoint: 481,
        options: {
          annotations: {
            points: [
              {
                marker: {
                  cssClass: "apexcharts-custom-class",
                  fillColor: "#7366FF",
                  radius: 2,
                  size: 10,
                  strokeColor: "#cfcdfc",
                  strokeWidth: 7,
                },
                x: "Feb",
                y: 44,
              },
            ],
          },
        },
      },
    ],
    stroke: {
      curve: "smooth",
      width: 3,
    },
    tooltip: {
      x: {
        show: false,
      },
      z: {
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: ["Jan", "Feb", "Mar", "Apr"],
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  },
  options8: {
    chart: {
      dropShadow: {
        blur: 10,
        color: "#FF3364",
        enabled: true,
        left: 0,
        opacity: 0.35,
        top: 3,
      },
      height: 130,
      type: "radialBar",
    },
    colors: ["#FF3364"],
    plotOptions: {
      radialBar: {
        dataLabels: {
          showOn: "always",
          value: {
            color: "var(--body-font-color)",
            fontSize: "14px",
            offsetY: -10,
            show: true,
          },
        },
        hollow: {
          size: "60%",
        },
        track: {
          margin: 5,
          opacity: 1,
          strokeWidth: "60%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 1500,
        options: {
          chart: {
            height: 130,
          },
        },
      },
    ],
    stroke: {
      lineCap: "round",
    },
  },
  series: [78],
  series1: [70],
  series2: [50],
  series3: [
    {
      data: [12, 10, 25, 12, 30, 10, 55, 45, 60],
      name: "growth",
      type: "line",
    },
    {
      data: [10, 15, 20, 18, 38, 25, 55, 35, 60],
      name: "growth",
      type: "line",
    },
  ],
  series4: [
    {
      data: [10, 12, 41, 36, 60, 58],
      name: "photo",
    },
  ],
  series5: [
    {
      data: [10, 12, 41, 36, 60, 58],
      name: "photo",
    },
  ],
  series6: [70, 60],
  series7: [
    {
      data: [20, 45, 30, 50],
      name: "view",
    },
  ],
  series8: [80],
}
export const chart = {
  candleChart9: {
    options: {
      chart: {
        height: 500,
        toolbar: {
          show: false,
        },
        type: "candlestick",
        width: 1530,
      },
      colors: [primary],
      plotOptions: {
        candlestick: {
          colors: {
            downward: secondary,
            upward: primary,
          },
        },
      },
      title: {
        align: "left",
        text: "CandleStick Chart",
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        tooltip: {
          enabled: true,
        },
      },
    },
    series: [
      {
        data: [
          {
            x: new Date(1538778600000),
            y: [6629.81, 6650.5, 6623.04, 6633.33],
          },
          {
            x: new Date(1538780400000),
            y: [6632.01, 6643.59, 6620, 6630.11],
          },
          {
            x: new Date(1538782200000),
            y: [6630.71, 6648.95, 6623.34, 6635.65],
          },
          {
            x: new Date(1538784000000),
            y: [6635.65, 6651, 6629.67, 6638.24],
          },
          {
            x: new Date(1538785800000),
            y: [6638.24, 6640, 6620, 6624.47],
          },
          {
            x: new Date(1538787600000),
            y: [6624.53, 6636.03, 6621.68, 6624.31],
          },
          {
            x: new Date(1538789400000),
            y: [6624.61, 6632.2, 6617, 6626.02],
          },
          {
            x: new Date(1538791200000),
            y: [6627, 6627.62, 6584.22, 6603.02],
          },
          {
            x: new Date(1538793000000),
            y: [6605, 6608.03, 6598.95, 6604.01],
          },
          {
            x: new Date(1538794800000),
            y: [6604.5, 6614.4, 6602.26, 6608.02],
          },
          {
            x: new Date(1538796600000),
            y: [6608.02, 6610.68, 6601.99, 6608.91],
          },
          {
            x: new Date(1538798400000),
            y: [6608.91, 6618.99, 6608.01, 6612],
          },
          {
            x: new Date(1538800200000),
            y: [6612, 6615.13, 6605.09, 6612],
          },
          {
            x: new Date(1538802000000),
            y: [6612, 6624.12, 6608.43, 6622.95],
          },
          {
            x: new Date(1538803800000),
            y: [6623.91, 6623.91, 6615, 6615.67],
          },
          {
            x: new Date(1538805600000),
            y: [6618.69, 6618.74, 6610, 6610.4],
          },
          {
            x: new Date(1538807400000),
            y: [6611, 6622.78, 6610.4, 6614.9],
          },
          {
            x: new Date(1538809200000),
            y: [6614.9, 6626.2, 6613.33, 6623.45],
          },
          {
            x: new Date(1538811000000),
            y: [6623.48, 6627, 6618.38, 6620.35],
          },
          {
            x: new Date(1538812800000),
            y: [6619.43, 6620.35, 6610.05, 6615.53],
          },
          {
            x: new Date(1538814600000),
            y: [6615.53, 6617.93, 6610, 6615.19],
          },
          {
            x: new Date(1538816400000),
            y: [6615.19, 6621.6, 6608.2, 6620],
          },
          {
            x: new Date(1538818200000),
            y: [6619.54, 6625.17, 6614.15, 6620],
          },
          {
            x: new Date(1538820000000),
            y: [6620.33, 6634.15, 6617.24, 6624.61],
          },
          {
            x: new Date(1538821800000),
            y: [6625.95, 6626, 6611.66, 6617.58],
          },
          {
            x: new Date(1538823600000),
            y: [6619, 6625.97, 6595.27, 6598.86],
          },
          {
            x: new Date(1538825400000),
            y: [6598.86, 6598.88, 6570, 6587.16],
          },
          {
            x: new Date(1538827200000),
            y: [6588.86, 6600, 6580, 6593.4],
          },
          {
            x: new Date(1538829000000),
            y: [6593.99, 6598.89, 6585, 6587.81],
          },
          {
            x: new Date(1538830800000),
            y: [6587.81, 6592.73, 6567.14, 6578],
          },
          {
            x: new Date(1538832600000),
            y: [6578.35, 6581.72, 6567.39, 6579],
          },
          {
            x: new Date(1538834400000),
            y: [6579.38, 6580.92, 6566.77, 6575.96],
          },
          {
            x: new Date(1538836200000),
            y: [6575.96, 6589, 6571.77, 6588.92],
          },
          {
            x: new Date(1538838000000),
            y: [6588.92, 6594, 6577.55, 6589.22],
          },
          {
            x: new Date(1538839800000),
            y: [6589.3, 6598.89, 6589.1, 6596.08],
          },
          {
            x: new Date(1538841600000),
            y: [6597.5, 6600, 6588.39, 6596.25],
          },
          {
            x: new Date(1538843400000),
            y: [6598.03, 6600, 6588.73, 6595.97],
          },
          {
            x: new Date(1538845200000),
            y: [6595.97, 6602.01, 6588.17, 6602],
          },
          {
            x: new Date(1538847000000),
            y: [6602, 6607, 6596.51, 6599.95],
          },
          {
            x: new Date(1538848800000),
            y: [6600.63, 6601.21, 6590.39, 6591.02],
          },
          {
            x: new Date(1538850600000),
            y: [6591.02, 6603.08, 6591, 6591],
          },
          {
            x: new Date(1538852400000),
            y: [6591, 6601.32, 6585, 6592],
          },
          {
            x: new Date(1538854200000),
            y: [6593.13, 6596.01, 6590, 6593.34],
          },
          {
            x: new Date(1538856000000),
            y: [6593.34, 6604.76, 6582.63, 6593.86],
          },
          {
            x: new Date(1538857800000),
            y: [6593.86, 6604.28, 6586.57, 6600.01],
          },
          {
            x: new Date(1538859600000),
            y: [6601.81, 6603.21, 6592.78, 6596.25],
          },
          {
            x: new Date(1538861400000),
            y: [6596.25, 6604.2, 6590, 6602.99],
          },
          {
            x: new Date(1538863200000),
            y: [6602.99, 6606, 6584.99, 6587.81],
          },
          {
            x: new Date(1538865000000),
            y: [6587.81, 6595, 6583.27, 6591.96],
          },
          {
            x: new Date(1538866800000),
            y: [6591.97, 6596.07, 6585, 6588.39],
          },
          {
            x: new Date(1538868600000),
            y: [6587.6, 6598.21, 6587.6, 6594.27],
          },
          {
            x: new Date(1538870400000),
            y: [6596.44, 6601, 6590, 6596.55],
          },
          {
            x: new Date(1538872200000),
            y: [6598.91, 6605, 6596.61, 6600.02],
          },
          {
            x: new Date(1538874000000),
            y: [6600.55, 6605, 6589.14, 6593.01],
          },
          {
            x: new Date(1538875800000),
            y: [6593.15, 6605, 6592, 6603.06],
          },
          {
            x: new Date(1538877600000),
            y: [6603.07, 6604.5, 6599.09, 6603.89],
          },
          {
            x: new Date(1538879400000),
            y: [6604.44, 6604.44, 6600, 6603.5],
          },
          {
            x: new Date(1538881200000),
            y: [6603.5, 6603.99, 6597.5, 6603.86],
          },
          {
            x: new Date(1538883000000),
            y: [6603.85, 6605, 6600, 6604.07],
          },
          {
            x: new Date(1538884800000),
            y: [6604.98, 6606, 6604.07, 6606],
          },
        ],
      },
    ],
  },
  chart1: {
    options: {
      chart: {
        height: 190,
        toolbar: {
          show: false,
        },
        type: "area",
        width: 510,
      },
      colors: [primary],
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [primary],
      },
      grid: {
        padding: {
          bottom: -50,
          left: 0,
          right: -10,
        },
        show: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: [
          "2018-09-19T00:00:00",
          "2018-09-19T01:30:00",
          "2018-09-19T02:30:00",
          "2018-09-19T03:30:00",
          "2018-09-19T04:30:00",
          "2018-09-19T05:30:00",
          "2018-09-19T06:30:00",
          "2018-09-19T07:30:00",
          "2018-09-19T08:30:00",
          "2018-09-19T09:30:00",
        ],
        labels: {
          show: false,
        },
        show: false,
        type: "datetime",
      },
      yaxis: {
        show: false,
      },
    },
    series: [
      {
        data: [14, 12, 20, 20, 14, 22, 18, 26, 12, 18, 0],
        name: "series1",
      },
    ],
  },
  chart2: {
    options: {
      chart: {
        height: 190,
        toolbar: {
          show: false,
        },
        type: "area",
        width: 510,
      },
      colors: [secondary],
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [secondary],
      },
      grid: {
        padding: {
          bottom: -50,
          left: 0,
          right: -10,
        },
        show: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: [
          "2018-09-19T00:00:00",
          "2018-09-19T01:30:00",
          "2018-09-19T02:30:00",
          "2018-09-19T03:30:00",
          "2018-09-19T04:30:00",
          "2018-09-19T05:30:00",
          "2018-09-19T06:30:00",
          "2018-09-19T07:30:00",
          "2018-09-19T08:30:00",
          "2018-09-19T09:30:00",
        ],
        labels: {
          show: false,
        },
        show: false,
        type: "datetime",
      },
      yaxis: {
        show: false,
      },
    },
    series: [
      {
        data: [14, 12, 20, 20, 14, 22, 18, 26, 12, 18, 0],
        name: "series1",
      },
    ],
  },
  chart3: {
    options: {
      chart: {
        height: 190,
        toolbar: {
          show: false,
        },
        type: "area",
        width: 510,
      },
      colors: ["#51BB25"],
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: ["#51BB25"],
      },
      grid: {
        padding: {
          bottom: -50,
          left: 0,
          right: -10,
        },
        show: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: [
          "2018-09-19T00:00:00",
          "2018-09-19T01:30:00",
          "2018-09-19T02:30:00",
          "2018-09-19T03:30:00",
          "2018-09-19T04:30:00",
          "2018-09-19T05:30:00",
          "2018-09-19T06:30:00",
          "2018-09-19T07:30:00",
          "2018-09-19T08:30:00",
          "2018-09-19T09:30:00",
        ],
        labels: {
          show: false,
        },
        show: false,
        type: "datetime",
      },
      yaxis: {
        show: false,
      },
    },
    series: [
      {
        data: [14, 12, 20, 20, 14, 22, 18, 26, 12, 18, 0],
        name: "series1",
      },
    ],
  },
  chart4: {
    options: {
      chart: {
        height: 350,
        toolbar: {
          show: false,
        },
        type: "bar",
        width: 855,
      },
      colors: [primary, secondary],
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [secondary, "#51bb25", primary],
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
        },
        yaxis: {
          show: false,
        },
      },
      legend: {
        show: true,
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          columnWidth: "30%",
          endingShape: "rounded",
          horizontal: false,
          radius: 10,
        },
      },
      stroke: {
        colors: ["transparent"],
        curve: "smooth",
        lineCap: "butt",
        show: true,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          },
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
        ],
      },
      yaxis: {
        title: {
          text: "$ (thousands)",
        },
      },
    },
    series: [
      {
        data: [100, 45, 30, 56, 45, 53, 41],
        name: "Profit",
      },
      {
        data: [75, 20, 40, 52, 50, 114, 94],
        name: "Revenue",
      },
      {
        data: [95, 55, 100, 48, 105, 60, 66],
        name: "Cash Flow",
      },
    ],
  },
  chart5: {
    labels: ["Volatility"],
    options: {
      chart: {
        height: 360,
        type: "radialBar",
        width: 585,
      },
      fill: {
        gradient: {
          gradientToColors: [primary],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          shade: "dark",
          shadeIntensity: 0.5,
          stops: [0, 100],
          type: "horizontal",
        },
        type: "gradient",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              color: "#111",
              fontSize: "17px",
              offsetY: -10,
              show: true,
            },
            show: true,
            value: {
              color: "#111",
              fontSize: "36px",
              formatter: function (val) {
                return parseInt(val)
              },
              show: true,
            },
          },
          endAngle: 225,
          hollow: {
            background: "#fff",
            dropShadow: {
              blur: 4,
              enabled: true,
              left: 0,
              opacity: 0.05,
              top: 3,
            },
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            margin: 0,
            position: "front",
            size: "70%",
          },
          startAngle: -135,
          track: {
            background: "#fff",
            dropShadow: {
              blur: 4,
              enabled: true,
              left: 0,
              opacity: 0.15,
              top: -3,
            },
            margin: 0,
            strokeWidth: "67%",
          },
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
    series: [70],
  },
  chart6: {
    labels: ["Skill 01", "Skill 02", "Skill 03"],
    options: {
      chart: {
        height: 350,
        offsetX: 20,
        offsetY: -30,
        type: "radialBar",
        width: 450,
      },
      colors: [secondary, primary, "#51bb25"],
      legend: {
        show: true,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            background: "transparent",
            margin: 5,
            size: "48%",
          },
          inverseOrder: false,
          size: undefined,
          track: {
            background: "#f2f2f2",
            margin: 3,
            opacity: 1,
            show: true,
            strokeWidth: "10%",
          },
        },
      },
    },
    series: [75, 63, 50],
  },
  chart7: {
    options: {
      chart: {
        height: 350,
        toolbar: {
          show: false,
        },
        type: "bar",
        width: 585,
        zoom: {
          enabled: false,
        },
      },
      colors: [primary],
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [primary],
      },
      legend: {
        show: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "55%",
          horizontal: false,
          radius: 10,
        },
      },
      stroke: {
        colors: ["transparent"],
        curve: "smooth",
        lineCap: "butt",
        show: true,
      },
      subtitle: {
        align: "right",
        floating: true,
        offsetY: 0,
        style: {
          fontSize: "22px",
        },
        text: "20%",
      },
      title: {
        align: "left",
        style: {
          fontSize: "12px",
        },
        text: "Load Average",
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands"
          },
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
    series: [
      {
        data: [200, 250, 330, 390, 420, 500, 580, 620, 700],
      },
    ],
  },
  chart8: {
    options: {
      chart: {
        height: 350,
        toolbar: {
          show: false,
        },
        type: "line",
      },
      colors: [secondary, primary],
      dataLabels: {
        enabled: false,
      },
      title: {
        text: "HeatMap Chart",
      },
    },
    series: [
      {
        data: [35, 45, 20, 42, 48, 35, 55, 60, 45, 65, 58, 62],
        name: "Complete",
      },
      {
        data: [35, 55, 50, 60, 75, 65, 64, 80, 90, 80, 83, 90],
        name: "Running",
      },
    ],
    stroke: {
      width: [2, 2],
    },
  },
  chart10: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
    ],
    options: {
      chart: {
        height: 320,
        toolbar: {
          show: false,
        },
        type: "line",
        width: 855,
      },
      colors: [primary, secondary],
      fill: {
        colors: [secondary, primary],
        opacity: 0.5,
      },
      markers: {
        size: 0,
      },
      stroke: {
        curve: "smooth",
        width: 0,
      },
      tooltip: {
        shared: true,
      },
      yaxis: [
        {
          title: {
            text: "Dollars in thounand",
          },
        },
      ],
    },
    series: [
      {
        data: [60, 90, 65, 80, 55, 72, 30, 50, 31],
        name: "TEAM A",
        type: "area",
      },
      {
        data: [20, 30, 25, 50, 32, 75, 65, 80, 45],
        name: "TEAM B",
        type: "area",
      },
    ],
  },
  chart11: {
    options: {
      chart: {
        height: 300,
        toolbar: {
          show: false,
        },
        type: "area",
      },
      colors: [primary],
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 0.2,
      },
      grid: {
        borderColor: "rgba(196,196,196, 0.3)",
        padding: {
          bottom: 0,
          right: -55,
          top: -20,
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },

      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
        ],
      },
    },
    series: [
      {
        data: [70, 60, 82, 80, 60, 90, 70, 120, 50, 60, 0],
      },
    ],
  },
  chart12: {
    options: {
      chart: {
        height: 400,
        toolbar: {
          show: false,
        },
        type: "area",
      },
      colors: [primary],
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 0.2,
      },
      grid: {
        borderColor: "rgba(196,196,196, 0.3)",
        padding: {
          bottom: 0,
          right: -16,
          top: -20,
        },
      },
      responsive: [
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 200,
            },
          },
        },
      ],
      stroke: {
        curve: "smooth",
        width: 3,
      },

      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
        ],
        tickAmount: 5,
        tickPlacement: "between",
        tooltip: {
          enabled: false,
        },
      },
    },
    series: [
      {
        data: [
          20, 120, 15, 100, 120, 60, 150, 70, 100, 80, 105, 20, 70, 60, 10, 12,
          10, 130, 60, 80, 40, 140, 110, 150, 30, 75, 20, 45, 15, 130, 10, 30,
          15, 110, 65, 130, 0,
        ],
      },
    ],
  },
  chart13: {
    options: {
      annotations: {
        xaxis: [
          {
            borderColor: primary,
            label: {
              borderColor: primary,
              offsetY: 20,
              style: {
                background: primary,
                color: "#fff",
              },
              text: "Anno Test",
            },
            strokeDashArray: 0,
            x: new Date("15 Nov 2017").getTime(),
          },
          {
            fillColor: "#51bb25",
            label: {
              borderColor: "##51bb25",
              offsetY: 20,
              style: {
                background: "#51bb25",
                color: "#fff",
                fontSize: "10px",
              },
              text: "X-axis range",
            },
            opacity: 0.1,
            x: new Date("17 Nov 2017").getTime(),
            x2: new Date("18 Nov 2017").getTime(),
          },
        ],
        yaxis: [
          {
            borderColor: "#f8d62b",
            fillColor: "#f8d62b",
            label: {
              borderColor: "#f8d62b",
              offsetX: -30,
              style: {
                background: "#f8d62b",
                color: "#fff",
                fontSize: "10px",
              },
              text: "Y-axis range",
            },
            opacity: 0.1,
            y: 8200,
            y2: 8400,
          },
        ],
      },
      chart: {
        height: 400,
        toolbar: {
          show: false,
        },
        type: "line",
      },
      colors: [secondary],
      dataLabels: {
        enabled: false,
      },
      grid: {
        padding: {
          left: 20,
          right: 30,
        },
      },
      labels: series.monthDataSeries1.dates,
      responsive: [
        {
          breakpoint: 576,
          options: {
            title: {
              style: {
                fontSize: "16px",
              },
            },
          },
        },
      ],
      stroke: {
        curve: "smooth",
        width: 3,
      },
      title: {
        align: "left",
        style: {
          fontFamily: "Rubik, sans-serif",
          fontSize: "18px",
          fontWeight: 500,
        },
        text: "Line with Annotations",
      },
      xaxis: {
        type: "datetime",
      },
    },
    series: [
      {
        data: series.monthDataSeries1.prices,
      },
    ],
  },
  chart14: {
    options: {
      chart: {
        dropShadow: {
          blur: 1,
          enabled: true,
          left: 1,
          top: 1,
        },
        height: 300,
        type: "radar",
      },
      colors: [primary, secondary, "#544fff"],
      fill: {
        gradient: {
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 0.8,
          shade: "light",
          shadeIntensity: 0.1,
          stops: [0, 100],
          type: "Reflected",
        },
        type: "gradient",
      },
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      markers: {
        size: 0,
      },
      stroke: {
        width: 0,
      },
      title: {
        text: "Radar Chart - Multi Series",
      },
    },
    series: [
      {
        data: [80, 50, 30, 40, 99, 20],
        name: "Series 1",
      },
      {
        data: [20, 30, 40, 80, 20, 80],
        name: "Series 2",
      },
      {
        data: [44, 76, 78, 13, 43, 10],
        name: "Series 3",
      },
    ],
  },
  chart15: {
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      colors: [primary, secondary, "#51bb25", "#544fff"],
      dataLabels: {
        enabled: false,
      },
      fill: {
        gradient: {
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 0.7,
          shade: "light",
          shadeIntensity: 0.4,
          stops: [0, 100],
          type: "horizontal",
        },
        type: "gradient",
      },
      title: {
        text: "Simple Bubble Chart",
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
        },
        show: false,
      },
      yaxis: {
        max: 70,
      },
    },
    series: [
      {
        data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
          max: 60,
          min: 10,
        }),
        name: "Bubble1",
      },
      {
        data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
          max: 60,
          min: 10,
        }),
        name: "Bubble2",
      },
      {
        data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
          max: 60,
          min: 10,
        }),
        name: "Bubble3",
      },
      {
        data: generateData(new Date("11 Feb 2017 GMT").getTime(), 20, {
          max: 60,
          min: 10,
        }),
        name: "Bubble4",
      },
    ],
  },
  progress1: {
    labels: ["Device 1", "Device 2"],
    options: {
      chart: {
        fontFamily: "Roboto, sans-serif",
        height: 70,
        sparkline: {
          enabled: true,
        },
        stacked: true,
        type: "bar",
        width: 990,
      },
      colors: ["rgba(115, 102, 255, 1)"],
      fill: {
        colors: ["rgba(115, 102, 255, 1)"],
      },
      legend: {
        show: true,
      },
      plotOptions: {
        bar: {
          barHeight: "10%",
          colors: {
            backgroundBarColors: ["rgba(115, 102, 255, 0.1)"],
          },
          horizontal: true,
        },
      },
      stroke: {
        width: 0,
      },
      subtitle: {
        align: "right",
        floating: true,
        offsetY: 0,
        text: "20%",
      },
      title: {
        floating: true,
        offsetX: -10,
        offsetY: 5,
        text: "Packed",
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: ["Process 1"],
      },
      yaxis: {
        max: 100,
      },
    },
    series: [
      {
        data: [20],
        name: "Process 1",
      },
    ],
  },
  progress2: {
    labels: ["Device 1", "Device 2"],
    options: {
      chart: {
        fontFamily: "Roboto, sans-serif",
        height: 70,
        sparkline: {
          enabled: true,
        },
        stacked: true,
        type: "bar",
        width: 990,
      },
      colors: ["#f73164"],
      fill: {
        colors: ["#f73164"],
      },
      legend: {
        show: true,
      },
      plotOptions: {
        bar: {
          barHeight: "10%",
          colors: {
            backgroundBarColors: ["rgba(249 ,79 ,123, 0.1)"],
          },
          horizontal: true,
        },
      },
      stroke: {
        lineCap: "round",
      },
      subtitle: {
        align: "right",
        floating: true,
        offsetY: 0,
        text: "40%",
      },
      title: {
        floating: true,
        offsetX: -10,
        offsetY: 5,
        text: "Dispatched",
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: ["Process 2"],
      },
      yaxis: {
        max: 100,
      },
    },
    series: [
      {
        data: [40],
        name: "Process 2",
      },
    ],
  },
  progress3: {
    labels: ["Device 1", "Device 2"],
    options: {
      chart: {
        fontFamily: "Roboto, sans-serif",
        height: 70,
        sparkline: {
          enabled: true,
        },
        stacked: true,
        type: "bar",
        width: 990,
      },
      colors: ["#a927f9"],
      fill: {
        colors: ["#a927f9"],
      },
      legend: {
        show: true,
      },
      plotOptions: {
        bar: {
          barHeight: "10%",
          colors: {
            backgroundBarColors: ["rgba(180, 74, 248, 0.1)"],
          },
          horizontal: true,
        },
      },
      stroke: {
        width: 0,
      },
      subtitle: {
        align: "right",
        floating: true,
        offsetY: 0,
        text: "50%",
      },
      title: {
        floating: true,
        offsetX: -10,
        offsetY: 5,
        text: "Reach Station",
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: ["Process 3"],
      },
      yaxis: {
        max: 100,
      },
    },
    series: [
      {
        data: [50],
        name: "Process 3",
      },
    ],
  },
  progress4: {
    labels: ["Device 1", "Device 2"],
    options: {
      chart: {
        fontFamily: "Roboto, sans-serif",
        height: 70,
        sparkline: {
          enabled: true,
        },
        stacked: true,
        type: "bar",
        width: 990,
      },
      colors: ["#F8D62B"],
      fill: {
        colors: ["#F8D62B"],
      },
      legend: {
        show: true,
      },
      plotOptions: {
        bar: {
          barHeight: "10%",
          colors: {
            backgroundBarColors: ["rgba(248,214, 43, 0.1)"],
          },
          horizontal: true,
        },
      },
      stroke: {
        width: 0,
      },
      subtitle: {
        align: "right",
        floating: true,
        offsetY: 0,
        text: "60%",
      },
      title: {
        floating: true,
        offsetX: -10,
        offsetY: 5,
        text: "Out for delivery",
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: ["Process 4"],
      },
      yaxis: {
        max: 100,
      },
    },
    series: [
      {
        data: [60],
        name: "Process 4",
      },
    ],
  },
  progress5: {
    labels: ["Device 1", "Device 2"],
    options: {
      chart: {
        fontFamily: "Roboto, sans-serif",
        height: 70,
        sparkline: {
          enabled: true,
        },
        stacked: true,
        type: "bar",
        width: 990,
      },
      colors: ["#51bb25"],
      fill: {
        colors: ["#51bb25"],
      },
      legend: {
        show: true,
      },
      plotOptions: {
        bar: {
          barHeight: "10%",
          colors: {
            backgroundBarColors: ["rgba(107, 197, 75, 0.1)"],
          },
          horizontal: true,
        },
      },
      stroke: {
        width: 0,
      },
      subtitle: {
        align: "right",
        floating: true,
        offsetY: 0,
        text: "10%",
      },
      title: {
        floating: true,
        offsetX: -10,
        offsetY: 5,
        text: "Delivered",
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        categories: ["Process 5"],
      },
      yaxis: {
        max: 100,
      },
    },
    series: [
      {
        data: [10],
        name: "Process 5",
      },
    ],
  },
}
