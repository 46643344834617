import bootstrap from "../../data/bootsrap.json"
import datatable from "../../data/datatable.json"

const state = {
  bootstrap: bootstrap.tableData1,
  datatable: datatable.Item,
  hoverable: bootstrap.hoverable,
  inverse: bootstrap.inverse,
  Responsive: bootstrap.Responsive,
  sizingTablesXl: bootstrap.sizingTablesXl,
  table: bootstrap.table2,
  tableData: bootstrap.tableData,
  tableData2: bootstrap.tableData2,
}

export default {
  namespaced: true,
  state,
}
