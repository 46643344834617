export const campaigns = [
  {
    campaign: "Jane Cooper",
    geo: "UK",
    maxParticipation: "$9,786",
    platformIcon: "fa fa-facebook",
    profitability: "45.6%",
    profitabilityIcon: "font-success me-1",
    status: "Active",
    statusClass: "btn badge-light-primary",
    tdBorderIcon: "border-icon facebook",
    type: "trending-up",
  },
  {
    campaign: "Floyd Miles",
    geo: "DE",
    maxParticipation: "$19,7098",
    platformIcon: "fa fa-instagram",
    profitability: "12.3%",
    profitabilityIcon: "font-danger me-1",
    status: "Active",
    statusClass: "btn badge-light-primary",
    tdBorderIcon: "border-icon instagram",
    type: "trending-down",
  },
  {
    campaign: "Guy Hawkins",
    geo: "ES",
    maxParticipation: "$90,986",
    platformIcon: "fa fa-pinterest",
    profitability: "65.6%",
    profitabilityIcon: "font-success me-1",
    status: "Active",
    statusClass: "btn badge-light-primary",
    tdBorderIcon: "border-icon pinterest",
    type: "trending-up",
  },
  {
    campaign: "Travis Wright",
    geo: "ES",
    maxParticipation: "$23,654",
    platformIcon: "fa fa-twitter",
    profitability: "35.6%",
    profitabilityIcon: "font-danger me-1",
    status: "Inactive",
    statusClass: "btn badge-light-light disabled",
    tdBorderIcon: "border-icon twitter",
    type: "trending-down",
  },
  {
    campaign: "Mark Green",
    geo: "UK",
    maxParticipation: "$12,796",
    platformIcon: "fa fa-youtube-play",
    profitability: "45.6%",
    profitabilityIcon: "font-success me-1",
    status: "Inactive",
    statusClass: "btn badge-light-light disabled",
    tdBorderIcon: "border-icon you-tube",
    type: "trending-up",
  },
]
