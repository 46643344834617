<template>
  <button
    :class="['custom-button', sizeClass, shapeClass, { outline, disabled: isDisabled }]"
    :disabled="isDisabled"
    @click.prevent="handleClick"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <slot />
  </button>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'

// Definindo as propriedades do botão
const props = defineProps({
  size: {
    type: String,
    default: 'medium', // Opções: 'small', 'medium', 'large'
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  shape: {
    type: String,
    default: 'default', // Opções: 'default', 'round'
  },
  outline: {
    type: Boolean,
    default: false, // Se 'true', ativa o estilo outline
  },
})

// Definindo os eventos que o botão emitirá
const emit = defineEmits(['click', 'mouseenter', 'mouseleave'])

const isDisabled = ref(props.disabled)

// Classes dinâmicas baseadas nas props
const sizeClass = `button-${props.size}`
const shapeClass = computed(() => (props.shape === 'round' ? 'button-round' : ''))

// Função para lidar com o clique e emitir o evento
function handleClick(event) {
  if (!isDisabled.value) {
    emit('click', event)
  }
}

// Função para hover
function handleMouseEnter(event) {
  emit('mouseenter', event)
}
function handleMouseLeave(event) {
  emit('mouseleave', event)
}
</script>

<style scoped>
.custom-button {
  background-color: var(--theme-deafult);
  color: var(--white);
  border: 1px solid var(--fc-button-border-color);
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hover e estado ativo */
.custom-button:hover {
  background-color: var(--theme-secondary);
  border: 1px solid var(--theme-secondary);
}
.custom-button:active {
  background-color: var(--fc-button-active-bg-color);
}

/* Estilos para o botão outline */
.custom-button.outline {
  background-color: transparent;
  color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.custom-button.outline:hover {
  background-color: var(--theme-deafult);
  color: var(--white);
}

/* Tamanhos */
.button-small {
  font-size: 0.8em;
  padding: 0.3em 0.8em;
}
.button-medium {
  font-size: 1em;
  padding: 0.5em 1em;
}
.button-large {
  font-size: 1.2em;
  padding: 0.8em 1.2em;
}

/* Forma redonda */
.button-round {
  border-radius: 50%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Desabilitado */
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: var(--light-bg);
  color: var(--chart-text-color);
  border: 1px solid var(--sidebar-border);
}
</style>
