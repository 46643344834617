import router from "@/router"
import { isTokenExpired } from "@/utils/jwt"
import axios from "axios"
import Cookies from "js-cookie"

const apiClient = axios.create({
  baseURL: `${process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_BASE_URL_API
    : process.env.VUE_APP_BASE_URL_API_DEV
    }`,
})

apiClient.interceptors.request.use(
  async (config) => {
    const accessToken = Cookies.get("accessToken")

    if (accessToken && !isTokenExpired(accessToken)) {
      config.headers.Authorization = `Bearer ${accessToken}`
    } else {
      router.push({ name: "login" })
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default apiClient
