<template>
  <div>
    <Breadcrumbs
      title="Tracking"
      router-name="shipmentMovements"
    />

    <div class="row center">
      <div :class="`col ${colClassCardStatusLefat}`">
        <CardStatusLeft :shipment="shipment" />
      </div>
      <div :class="`col ${colClassStatus}`">
        <CardStatus
          :shipment="shipment"
          :status="status"
        />
        <TimeLine
          :events="timelineFormated"
          :title="$t('timeline')"
          :sub-title="$t('trackShipmentMovements')"
        />

        <FileCard
          :files="filesFormated"
          @view="download"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end mb-4">
      <b-button
        outline
        @click="goBack"
      >
        {{ $t("back") }}
      </b-button>
    </div>
  </div>
</template>

<script setup>
import TimeLine from "@/components/TimeLine.vue"
import { onMounted, ref, onBeforeUnmount } from "vue"
import CardStatus from "./components/details/CardStatus.vue"
import CardStatusLeft from "./components/details/CardStatusLeft.vue"
import FileCard from "@/components/FileCard.vue"
import { storeToRefs } from "pinia"
import { detailsShipmentStore } from "./store/detailsShipmentStore"
import router from "@/router"

import { useRoute } from "vue-router"
import { useWindowSize } from "@vueuse/core"
import { computed } from "vue"

const route = useRoute()

const { width } = useWindowSize()

const params = ref(route.query)

const store = detailsShipmentStore()
const { fetchShipment, resetStore } = store

const { shipment, timelineFormated, filesFormated } = storeToRefs(store)
const { downloadFile } = store

onMounted(() => {
  fetchShipment(params.value)
})

onBeforeUnmount(() => {
  resetStore()
})

const widthDefault = 1500

const status = computed(() => {
  const timelineFiltered = timelineFormated.value?.filter(
    (item) => item.checked === true
  )
  const lastItemTimeline = timelineFiltered?.at(-1)
  return lastItemTimeline ? lastItemTimeline.description : ""
})

const colClassCardStatusLefat = computed(() =>
  width.value < widthDefault ? "col-3" : "col-2"
)

const colClassStatus = computed(() =>
  width.value < widthDefault ? "col-9" : "col-10"
)

const goBack = () => {
  router.push({ name: "shipmentMovements" })
}

const download = (file) => {
  if (!file || !file.path) return
  downloadFile(file.path, file.name)
}
</script>

<style scoped>
.center {
  display: flex;
  justify-content: center;
}
</style>
