export const overallbalance = [
  {
    amount: "$22,678",
    balance: "+$456",
    balanceClass: "font-success",
    iconName: "income",
    title: "Income",
  },
  {
    amount: "$12,057",
    balance: "+$456",
    balanceClass: "font-danger",
    iconName: "expense",
    title: "Expense",
  },
  {
    amount: "$8475",
    iconName: "doller-return",
    title: "Cashback",
  },
]

export const activities = [
  {
    class: "activity-dot-primary",
    date: "8th March, 2022",
    day: "1 day ago",
    decription: "I don't like things to be staged or fussy.",
    title: "Updated Product",
  },
  {
    class: "activity-dot-warning",
    date: "15th Oct, 2022",
    day: "Today",
    decription: "If you have it, you can make anything look good.",
    title: "Tello just like your product",
  },
  {
    class: "activity-dot-secondary",
    date: "20th Sep, 2022 ",
    day: "12:00 PM",
    decription: "I like to design everything to do with the body.",
    title: " Tello just like your product",
  },
]

export const resentSales = [
  {
    amount: "$200.00",
    image: require("@/assets/images/dashboard/user/1.jpg"),
    name: "Jane Cooper",
    time: "10 minutes ago",
  },
  {
    amount: "$970.00",
    image: require("@/assets/images/dashboard/user/2.jpg"),
    name: "	Brooklyn Simmons",
    time: "19 minutes ago",
  },
  {
    amount: "$300.00",
    image: require("@/assets/images/dashboard/user/3.jpg"),
    name: "Leslie Alexander",
    time: "2 hours ago",
  },
  {
    amount: "$450.00",
    image: require("@/assets/images/dashboard/user/4.jpg"),
    name: "Travis Wright",
    time: "8 hours ago",
  },
  {
    amount: "$768.00",
    image: require("@/assets/images/dashboard/user/5.jpg"),
    name: "Mark Green",
    time: "1 day ago",
  },
]

export const paperNotes = [
  {
    label: "SAAS",
    labelClass: "badge badge-light-primary",
  },
  {
    label: "E-Commerce",
    labelClass: "badge badge-light-success",
  },
  {
    label: "Crypto",
    labelClass: "badge badge-light-warning",
  },
  {
    label: "Project",
    labelClass: "badge badge-light-info",
  },
  {
    label: "NFT",
    labelClass: "badge badge-light-secondary",
  },
  {
    label: "+9",
    labelClass: "badge badge-light-light",
  },
]

export const customers = [
  {
    image: require("@/assets/images/dashboard/user/1.jpg"),
  },
  {
    image: require("@/assets/images/dashboard/user/6.jpg"),
  },
  {
    image: require("@/assets/images/dashboard/user/7.jpg"),
  },
  {
    image: require("@/assets/images/dashboard/user/3.jpg"),
  },
  {
    image: require("@/assets/images/dashboard/user/8.jpg"),
  },
]

export const puechaseSalesDetails1 = [
  {
    growthClass: "font-secondary f-w-500",
    growthNumber: "+50%",
    iconClass: "icon-arrow-up icon-rotate me-1",
    number: "10,000",
    svgIcon1: "cart",
    svgIcon2: "halfcircle",
    title: "Purchase",
    widgetClass: "widget-round secondary",
  },
  {
    growthClass: "font-primary f-w-500",
    growthNumber: "+70%",
    iconClass: "icon-arrow-up icon-rotate me-1",
    number: "4,200",
    svgIcon1: "tag",
    svgIcon2: "halfcircle",
    title: "Sales",
    widgetClass: "widget-round primary",
  },
]
export const puechaseSalesDetails2 = [
  {
    growthClass: "font-warning f-w-500",
    growthNumber: "-20%",
    iconClass: "icon-arrow-down icon-rotate me-1",
    number: "7000",
    svgIcon1: "return-box",
    svgIcon2: "halfcircle",
    title: "Sales return",
    widgetClass: "widget-round warning",
  },
  {
    growthClass: "font-success f-w-500",
    growthNumber: "+70%",
    iconClass: "icon-arrow-up icon-rotate me-1",
    number: "5700",
    svgIcon1: "rate",
    svgIcon2: "halfcircle",
    title: "Purchase rate",
    widgetClass: "widget-round success",
  },
]
