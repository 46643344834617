import Courses from "../../data/courses.json"

const state = {
  courses: Courses.courses,
}

const getters = {
  normalcourses: (state) => {
    return state.courses.filter((course) => {
      if (course.special === 0) return course
    })
  },
  specialcourses: (state) => {
    return state.courses.filter((course) => {
      if (course.special === 1) return course
    })
  },
}

export default {
  getters,
  namespaced: true,
  state,
}
