<template>
  <div class="b-row">
    <slot></slot>
  </div>
</template>

<style scoped>
.b-row {
  display: flex;
  flex-wrap: wrap; /* Permite que os itens se movam para a próxima linha, se necessário */
  margin: -4px;
  width: 100%;
}

/* Ajuste o espaço dos itens */
.b-row > * {
  padding: 4px; /* Adiciona espaço entre os itens */
}

/* Media queries para melhorar a responsividade em diferentes tamanhos de tela */
@media (max-width: 600px) {
  .b-row {
    flex-direction: column; /* Altera para coluna em telas menores */
  }
}

@media (min-width: 601px) {
  .b-row {
    flex-direction: row; /* Mantém o layout em linha em telas maiores */
  }
}
</style>
